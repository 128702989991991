import React, { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Button as ReactStrapButton } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import dateFormats from '../../../UI/FormatDate/formatDate';

import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import AccountsFormModal from './AccountsForm/AccountsFormModal';
import { GlobalContext } from '../../../App/App';
import StatementPdf from './AccountsForm/StatementPDf';
import './Accounts.scss'
import { getFormattedCurrencyValue } from '../../../../utils/utils';
import configMessages from '../../../../config/configMessages';

function Accounts(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [type, setType] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenStatementPdf, setIsOpenStatementPdf] = useState(false);
  const getDataFromServer = Debounce(fetchData, 300);//To Avoid the over iterating
  const context = useContext(GlobalContext);

  const hideButtons = props.displayFrom == "patientScreens" ? true : false;

  useEffect(() => {
    const success = searchParams.get('success');
    const pId = searchParams.get('patientId');
    if (success) {
      // fetchMethodRequest('GET', apiCalls.patients + '/' + pId).then(res => {
      //   if (res?.details) {
      //     setTimeout(() => {
      //       localStorage.setItem('PatientData', JSON.stringify(res.details));
      //     }, 100);
      showToasterMessage('Payment success', 'success');
      navigate('/accounts');
      //   }
      // })
    }
    getDataFromServer(localStorage.PatientData && !success ? true : false);

  }, []);

  useEffect(() => {
    window.addEventListener('PatientBar', getDataFromServer);

    return () => {
      window.removeEventListener('PatientBar', getDataFromServer);
    };
  }, []);

  /**
   * 
   * @param {Function} func 
   * @param {Number} delay 
   * @returns 
   */

  function Debounce(func, delay) {//To Avoid the over iterating
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };


  function fetchData(displayToasterMsg) {
    const storedPatientData = localStorage.getItem('PatientData');
    const patientData = JSON.parse(storedPatientData);

    setIsLoading(true);
    let filterCriteria = {
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    if (patientData && patientData._id) {
      filterCriteria.criteria.push({
        key: "patientId",
        value: patientData._id,
        type: "eq"
      });
    } else {
      if(!hideButtons)showToasterMessage('Please Select a Patient', 'warning');
      setTreatments([]);
      setAccounts([]);
      setIsLoading(false);
      return;
    }
    const encodedFilter = encodeURIComponent(JSON.stringify(filterCriteria));
    const accountsRequestUrl = `${apiCalls.accounts}?searchFrom=autoComplete&filter=${encodedFilter}&type=exportToCsv`;

    fetchMethodRequest('GET', accountsRequestUrl)
      .then(response => {
        let flattenedTreatments = [];
        let accounts = [];

        if (response && response.accounts && response.accounts.length > 0) {
          accounts = response.accounts;
          flattenedTreatments = response.accounts.reduce((acc, account, index) => {
            const treatments = account.patientTreatmentDetails.map((treatment, treatmentIndex) => ({
              ...treatment,
              patientId: account.patientId, // Assuming there's a patientId field
              patientName: account.patientId.name,
              createdByName: account.createdByName,
              created: account.created,
              createdDate: moment(treatment?.created).format(config.dateFormat),
              accountId: account._id,
              procedureId: treatment._id ? `${treatment._id}-${treatment.teethNumber}-${index}-${treatmentIndex}` : index + '_' + treatmentIndex,
            }));
            return [...acc, ...treatments];
          }, []);
          displayToasterMsg && !hideButtons ? showToasterMessage('Data fetched successfully', 'success') : null;

        } else if (!hideButtons) {
          showToasterMessage('No data found', 'error');
        }
        setAccounts(accounts);
        setTreatments(flattenedTreatments);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        showToasterMessage('Failed to fetch data', 'error');
        setIsLoading(false);
      });
  }

  const descriptionStyle = (data, column) => {
    let colors = { paypal: "#22c55e", cash: "#22c55e", cheque: "#22c55e", cardknox: "#22c552" };
    const style = { display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }
    const val = data?.[column.field];
    style.maxWidth = hideButtons ? '150px' : "400px";
    for (let key of Object.keys(colors)) {
      if (val && val.toLowerCase().includes(key) && data.paymentAmount) {
        return <span title={val} style={{ color: `${colors[key]}`, fontWeight: "bold", ...style }}>{val}</span>
      }
    }
    return val && val != "-" ? <span title={val} style={style}>{val}</span> : hyphenStyle(data, column);
  }

  const hyphenStyle = (data, column) => {
    if (data[column.field] && data[column.field] != "-") return <div style={{ textTransform: 'capitalize' }}>{data[column.field]}</div>
    else return <span style={{ fontSize: "1.2rem", fontWeight: "bolder", color: "#1e40af", textTransform: 'capitalize' }}>{data[column.field]}</span>
  }

  const dollarStyle = (data, column) => {
    if (data[column.field] && data[column.field] != "-") {
      return <div style={{ textAlign: "right", paddingRight: '10px' }}>
        {/* <FontAwesomeIcon
          className='genderIconAlignment'
          color='white'
          icon='dollar-sign'
          title="Delete"
          style={{ color: '#85BB65', width: '10'}}
          /> */}
        {/* <span className="ms-1">{data[column.field]?.toLocaleString()}</span> */}
        <span className="ms-1">{getFormattedCurrencyValue(data[column.field])}</span>
      </div>
    }
    else {
      return hyphenStyle(data, column);
    }
  }

  // Define columns in an array
  const columns = [
    { field: 'createdDate', header: 'Date', width: '100px', align: 'center' },
    // { field: 'patientName', header: 'Patient Name', width: 'auto', align: 'left', className: 'custom-header-left' },
    { field: 'code', header: 'Code', width: '100px', align: 'left', customStyle: hyphenStyle },
    { field: 'description', header: 'Description', width: 'auto', align: 'left', className: 'custom-header-left', customStyle: descriptionStyle },
    { field: 'teethNumber', header: 'TTH', width: '100px', align: 'center', customStyle: hyphenStyle },

    // { field: 'amountCharges', header: 'Charges', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'fee', header: 'Charges', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'amountCredits', header: 'Discount', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'amountCharges', header: 'Adjustment', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'amountRefund', header: 'Refund', width: 'auto', align: 'center', customStyle: dollarStyle },
    // { field: 'amountCredits', header: 'Credits', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'paymentAmount', header: 'Credits', width: 'auto', align: 'center', customStyle: dollarStyle },
    { field: 'balanceAmount', header: 'Balance', width: 'auto', align: 'center', customStyle: dollarStyle },
    // { field: 'paymentAmount', header: 'Payment', width: 'auto', align: 'center', customStyle: dollarStyle },
    // { field: 'fee', header: 'Fee', width: 'auto', align: 'center', customStyle: dollarStyle },
  ];

  const onRowSelect = (e) => {
    setSelectedRows(e.value);
  };

  const getRowClassName = (data) => {
    if (data.type === 'additions') {
      return 'additions-row';
    } else if (data.type === 'subtractions') {
      return 'subtractions-row';
    }
    return '';
  };

  function renderDataTable() {
    return (
      <DataTable value={treatments} loading={isLoading} responsiveLayout="stack"
        selectionMode="multiple" selection={selectedRows} onSelectionChange={onRowSelect}
        dataKey='procedureId'
        style={{ height: 'calc(100% - 100px)' }}
        rowClassName={getRowClassName} scrollable scrollHeight="110%"
        emptyMessage={configMessages.noRecords}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '3rem', textAlign: 'center' }}
          bodyStyle={{ textAlign: 'center' }}
        />
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={col.customStyle && col.customStyle}
            style={{ width: col.width, textAlign: col.align }}
            className={col.className || ''}
          />
        ))}
      </DataTable>
    );
  }

  const openFormModal = (type) => {
    setType(type);
    setIsOpenFormModal(true);
  };

  const downloadStatements = async () => {
    const obj = treatments[0];
    const payload = {};

    // const filterKeys = ['patientId', 'accountId']; // Specify the keys you want to keep
    // const payload = Object.fromEntries(Object.entries(obj).filter(([key]) => filterKeys.includes(key)));

    payload.total = accounts[0]?.totalAmount;
    payload.patientId = obj.patientId;
    payload.accountId = obj.accountId;
    payload.statement = treatments;
    payload.date = moment().format(config.dateFormat);

    try {
      const response = await axios.post(`${config.apiUrl}${apiCalls.accountsStatement}`, payload, {
        responseType: 'blob',
      });

      // Create a blob URL
      const pdfBlobUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

      // Create and click a temporary link to download the file
      const link = document.createElement('a');
      link.href = pdfBlobUrl;
      link.download = `${payload?.patientId?.name ? payload?.patientId?.name + '_' : ''}statement.pdf`; // Specify the filename
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Revoke the blob URL
      URL.revokeObjectURL(pdfBlobUrl);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  }

  const closeFormModal = () => {
    setType();
    setIsOpenFormModal(false);
    getDataFromServer();
  };

  const getUrlData = (type) => {
    const data = {};
    data.method = type == 'payment' ? "POST" : "PUT";
    data.parentField = type == 'payment' ? "paymentInfo" : "adjustment";
    data.url = type == 'payment' ? apiCalls.makePayments : `${apiCalls.accountsAdjustment}/${treatments[0]?.accountId}`;
    data.addData = { accountId: treatments[0]?.accountId };
    return data;
  }

  const createInsuranceClaimManagement = () => {
    let patientId = context?.patientData;
    let statusDescription = selectedRows;
    let patientIdSearch = patientId?.name;
    let userBody = {
      patientId: patientId,
      patientIdSearch: patientIdSearch,
      statusDescription: statusDescription
    };

    return fetchMethodRequest("POST", apiCalls.claimManagements, userBody)
      .then(response => {
        if (response && response.respMessage) {
          showToasterMessage(response.respMessage, 'success');
        } else {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(err => {
        showToasterMessage(err.message || 'Network error occurred', 'error');
        console.error('Error in createInsuranceClaimManagement:', err);
      })
      .finally(() => {
        setSelectedRows([]);
      });
  };

  //Open Statement pdf Dialog
  const toggleStatementPdf = () => {
    setIsOpenStatementPdf(!isOpenStatementPdf);
  };


  return (
    <div className='round accounts_screen'>
      {hideButtons && <div className='row'>
        <h3 className='heading col-md-5'>Accounts</h3>
        {props.children ? props.children : null}
      </div>}
      <div className="d-flex flex-wrap align-items-start justify-content-between">
        <div>
          <ReactStrapButton className='m-1' color="primary" onClick={() => openFormModal('payment')} disabled={treatments?.length == 0}>Payment</ReactStrapButton>
          <ReactStrapButton className='m-1' color="primary" onClick={() => openFormModal('adjustment')} disabled={treatments?.length == 0}>Adjustment</ReactStrapButton>
          <ReactStrapButton className='m-1' color="primary" onClick={createInsuranceClaimManagement} disabled={selectedRows.length === 0}>New Claim</ReactStrapButton>
          <ReactStrapButton className='m-1' color="primary" onClick={toggleStatementPdf} disabled={treatments?.length == 0}>Statement</ReactStrapButton>
        </div>
        <div className="ml-auto">
          <p style={{ fontSize: '1.2rem', fontWeight: "bold", marginRight: "1rem" }}>
            Total Amount:
            <span style={{ color: "#075985", fontSize: "1.5rem", marginLeft: "5px" }}>
              {accounts && accounts[0]?.totalAmount ? getFormattedCurrencyValue(accounts[0]?.totalAmount) : "0"}</span>
          </p>
        </div>

      </div>
      {renderDataTable()}

      {isOpenFormModal && <AccountsFormModal
        isOpenFormModal={isOpenFormModal}
        type={type}
        closeFormModal={closeFormModal}
        getUrlData={getUrlData}
        accounts={accounts}
      />
      }
      {isOpenStatementPdf && (
        <StatementPdf
          visible={isOpenStatementPdf}
          onHide={() => setIsOpenStatementPdf(false)}
          treatments={treatments}
          accounts={accounts}
          totalAmount={accounts && accounts[0]?.totalAmount}
        />
      )}
    </div>
  );

}

export default Accounts;
