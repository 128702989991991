import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer, Views, Navigate } from 'react-big-calendar';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { useForm, Controller } from 'react-hook-form';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import '../../../scss/component/schedulecalendar.scss';
import '../../../scss/component/draganddrop.scss';
import CreateSlotModal from '../../Cruds/CommonModals/AddSlot';
import UpdateSlotModal from '../../Cruds/CommonModals/UpdateSlotModal';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faBookmark, faBan, faCalendar, faTrash,faAngleLeft, faAngleRight ,faCaretDown ,faCopy,faListOl,faCalendarCheck} from '@fortawesome/free-solid-svg-icons';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import config from '../../../config/config';
import { Dialog } from 'primereact/dialog';
import '../../../scss/component/draganddrop.scss';
import { ProgressSpinner } from 'primereact/progressspinner';
import Loader from '../../App/Loader';
import formatDate from '../../UI/FormatDate/formatDate';
import Moment from 'moment';
import { Toast } from 'primereact/toast';
import clsx from 'clsx';
import { TabView, TabPanel } from 'primereact/tabview';
import SchedulesDataTableComponent from './SchedulesDataTable';
import WeekendConfirmModal from '../../Cruds/CommonModals/WeekendConfirmModal';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import { GlobalContext } from '../../App/App';
import Schedules from '../../Cruds/Schedules';
const ScheduleCalendar = forwardRef((props, ref) => {

  const currentTime = new Date();
  currentTime.setHours(currentTime.getHours() - 1, 0, 0, 0);
  const [events, setEvents] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [eventDate, setEventDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [eventNameLabel, setEventNameLabel] = useState('');
  const [date, setDate] = useState(new Date());
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDragConfirmDialogVisible, setIsDragConfirmDialogVisible] = useState(false);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [resizeEvent, setResizeEvent] = useState(null);
  const [isResizeConfirmDialogVisible, setIsResizeConfirmDialogVisible] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [handlers, setHandlers] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDateTime, setCurrentDateTime] = useState();
  const toast = useRef(null);
  const [scrollTime, setScrollTime] = useState(currentTime);
  const [isResourceChanged, setIsResourceChanged] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPasteEvent, setSelectedPasteEvent] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [pasteEventOperative, setPasteEventOperative] = useState(null);
  const [viewType, setViewType] = useState('today');
  const [calendarFromTime , setCalendarFromtime] = useState(new Date());
  const [calendarToTime , setCalendarToTime] = useState(new Date());
  const [timesAreSet, setTimesAreSet] = useState(false);
  const [showWeekendConfirmation, setShowWeekendConfirmation] = useState(false);
  const [tempFormData, setTempFormData] = useState(null);
  const [wekendDatesInformation, setWekendDatesInformation] = useState();
  const [isWeekendConfirm, setIsWeekendConfirm] = useState(false);
  const [copiedDate, setCopiedDate] = useState();
  const [copiedType, setCopiedType] = useState(null);
  const [breakStartTime, setBreakStartTime] = useState(null);
  const [breakEndTime, setBreakEndTime ] = useState(null);
  const [clinicData, setClinicData] = useState({});
  const [showWeekendConfirmationForUpdate, setShowWeekendConfirmationForUpdate] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm(
      // {resolver: yupResolver(schema),}
    );

  const localizer = momentLocalizer(moment)
  const DnDCalendar = withDragAndDrop(Calendar);

  const context = useContext(GlobalContext);  
  useEffect(() => {
    
    window.addEventListener('PatientBar', fetchChairsData);

    return () => {
      window.removeEventListener('PatientBar', fetchChairsData);
    };
  });
 

  useEffect(() => {
    fetchChairsData();
  }, []);
  
  useEffect(() => {
    if (resources.length > 0) {
      getSchedulesData();
    }
  }, [resources]);
useEffect(() => {
getSchedulesData();
}, [currentDate]);

useEffect(() => {
getSchedulesData();
}, [localStorage.getItem('ClinicData')]);
  
useEffect(() => {
getSchedulesData();
}, [activeIndex]);

  useEffect(() => {
    const selectedOrthoIndex = resources.findIndex(r => r.resourceId === props.selectedHandler?._id);
    if (selectedOrthoIndex > 0) {
      const selectedOrtho = resources[selectedOrthoIndex];
      resources.splice(selectedOrthoIndex, 1);
      resources.unshift(selectedOrtho);
      setResources([...resources]);
    }

    getSchedulesData();
  }, [props.selectedHandler]);


  useEffect(()=>{
    setScrollTime(currentTime);
  },[]);

  useEffect(() => {
    if (currentDateTime) {
      const newScrollTime = new Date(currentDateTime.date.getFullYear(), currentDateTime.date.getMonth(), currentDateTime.date.getDate(), currentDateTime.time.hours, currentDateTime.time.minutes);
      setScrollTime(newScrollTime);
    } else {
      setScrollTime(currentTime);
    }
  }, [currentDateTime]);

  useEffect(() => {
    getSchedulesData();
  }, [viewType]);


  // Function to convert time string from 12-hour AM/PM format to a Date object in 24-hour format
  function convertTo24HourFormat(timeStr, currentDate) {
    const timeParts = timeStr.split(':');
    const hourPart = parseInt(timeParts[0], 10);
    const minutesAndAmPm = timeParts[1].split(' ');
  
    // Check if the time string has AM/PM
    if (timeStr.toLowerCase().includes('am') || timeStr.toLowerCase().includes('pm')) {
      const minutePart = minutesAndAmPm[0];
      const amPm = minutesAndAmPm[1];
      const hour24 = amPm.toLowerCase() === 'pm' ? (hourPart < 12 ? hourPart + 12 : hourPart) : (hourPart === 12 ? 0 : hourPart);
      return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hour24, parseInt(minutePart, 10));
    } else {
      // If no AM/PM, assume the format is already 24-hour
      const minutes = parseInt(minutesAndAmPm[0], 10);
      return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hourPart, minutes);
    }
  }

  
  function convertBreakHourFormat(timeStr) {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');

    // Convert hours to integer to handle '12' correctly
    hours = parseInt(hours, 10);

    if (hours === 12) {
        hours = 0; // Handle 12 AM as 00 hours
    }

    if (modifier === 'PM' && hours !== 12) {
        hours += 12; // Convert PM hour to 24-hour format
    }

    return `${hours.toString().padStart(2, '0')}:${minutes}`; // Return formatted time string
}

  useEffect(() => {
    const currentDate = new Date();
    let clinicData = JSON.parse(localStorage.getItem('ClinicData')) ? JSON.parse(localStorage.getItem('ClinicData')) : '';
    if (clinicData.startTime) {
      const calendarFromTime = convertTo24HourFormat(clinicData.startTime, currentDate);
      setCalendarFromtime(calendarFromTime);
    }
  
    if (clinicData.endTime) {
      const calendarToTime = convertTo24HourFormat(clinicData.endTime, currentDate);
      setCalendarToTime(calendarToTime);
    }
  
    if (clinicData.breakStartTime) {
      const breakStartTimeParts = convertBreakHourFormat(clinicData.breakStartTime);
      setBreakStartTime(breakStartTimeParts);
    }
  
    if (clinicData.breakEndTime) {
      const breakEndTimeParts = convertBreakHourFormat(clinicData.breakEndTime);
      setBreakEndTime(breakEndTimeParts);
    }
  
    if (clinicData.startTime && clinicData.endTime) {
      setTimesAreSet(true)
    } else {
      setTimesAreSet(false)
    }

  }, [localStorage.getItem('ClinicData')]);
  const getWeekRange = (date) => {
    const startOfWeek = moment(date).startOf('week').toDate();
    const endOfWeek = moment(date).endOf('week').toDate();
    return { startOfWeek, endOfWeek };
  };

  const fetchChairsData = () => {
    const storedClinicDataString = localStorage.getItem('ClinicData');
    if (!storedClinicDataString) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Select clinic id in patient bar.' });
      return;
    }
    const storedClinicData = JSON.parse(storedClinicDataString);
    setClinicData(storedClinicData);
    fetchMethodRequest('GET', `${apiCalls.clinics}/${storedClinicData._id}`)
      .then(response => {
        if (response) {
          const handlers = response.chairs;
          const handlerResources = handlers.map(opt => ({
            resourceId: opt._id,
            resourceTitle: opt.name,
          }));

          handlerResources.sort((a, b) => {
            const numA = parseInt(a.resourceTitle.replace(/\D/g, ''), 10);
            const numB = parseInt(b.resourceTitle.replace(/\D/g, ''), 10);
            if (numA === numB) {
              return a.resourceTitle.localeCompare(b.resourceTitle);
            }
            return numA - numB;
          });

          setResources(handlerResources);
          setHandlers(handlers);
        }
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  };
/**
* Fetches slot data based on filters and updates the state.
*/
  const getSchedulesData = () => {

    let filterCriteria = {
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    // if (props.selectedHandler) {
    //   filterCriteria.criteria.push({
    //     key: "handler",
    //     value: props.selectedHandler._id,
    //     type: "eq"
    //   });
    // }
    if (viewType === 'week') {
      const { startOfWeek, endOfWeek } = getWeekRange(currentDate);
      filterCriteria.criteria.push({
        key: "fromTime",
        value: moment(startOfWeek).format("YYYY-MM-DD"),
        type: "fromDateToDate"
      }, {
        key: "toDate",
        value: moment(endOfWeek).format("YYYY-MM-DD"),
        type: "fromDateToDate"
      });
    } else { 
      filterCriteria.criteria.push({
        key: "fromTime",
        // value: formatDate.formatDate(currentDate),//moment(currentDate),//.format("YYYY-MM-DD"),
        value:  moment(currentDate).format(config.dbOnlyDateFormat),
        type: "dateeq"
      });
    }
    

    // Add clinic ID to filter criteria if clinicData is present
    if (localStorage.getItem('ClinicData')) {
      let clinicData = JSON.parse(localStorage.getItem('ClinicData'));
      filterCriteria.criteria.push({
        key: "clinicId",
        value: clinicData._id,
        type: "eq"
      });
    }
  
    
    const encodedFilter = encodeURIComponent(JSON.stringify(filterCriteria));

    const requestUrl = `${apiCalls.schedules}?searchFrom=autoComplete&filter=${encodedFilter}&type=exportToCsv`;

    fetchMethodRequest('GET', requestUrl)
      .then(async (response) => {
        if (response && response.schedules) {
          let scheduleData = response.schedules;
          setSchedules(scheduleData);
          const defaultEvents = [];
          const scheduleOptions = [];

          scheduleData.forEach((schedule, index) => {
              const dateComponents = new Date(schedule.fromTime);

              const fromTimeComponents = new Date(schedule.fromTime);
              const startHour = fromTimeComponents.getHours();
              const startMinute = fromTimeComponents.getMinutes();

              const toTimeComponents = new Date(schedule.toTime);
              const endHour = toTimeComponents.getHours();
              const endMinute = toTimeComponents.getMinutes();

              let handler = schedule.handlers ? `${schedule.handlers.name} ` :
                schedule.operative ? `${schedule.operative.name}  ` :
                  '';
              let status = schedule.status;
              const title = `${handler}`;

              // const title = `${slot.status}`;
              // Combine date with start time
              const startDateTime = new Date(dateComponents);
              startDateTime.setHours(startHour, startMinute);

              // Combine date with end time
              const endDateTime = new Date(dateComponents);
              endDateTime.setHours(endHour, endMinute);
              const handlerResource = resources.find(hd => hd.resourceId === schedule.handlers._id);
              const isSchedule = "True";

              defaultEvents.push({
                _id: schedule._id,
                title: title,
                start: startDateTime,
                end: endDateTime,
                allDay: false,
                handler: handler,
                resourceId: handlerResource ? handlerResource.resourceId : undefined,
                status: status,
                date: dateComponents,
                isSchedule: isSchedule,
                slotId : schedule._id,
              });

              scheduleOptions.push({
                value: schedule._id,
                label: title
              });
            
          });
          // setEvents(defaultEvents);
          setEvents(defaultEvents);
        }
      }).catch(error => {
        console.error('Error fetching slots:', error);
      });
  };

    const formatTime = (targetHours) => {
      const date = new Date();    
      date.setHours(targetHours, 0, 0, 0);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
    
      // Construct the formatted time string
      return `${hours}:${minutes}`;
    }


  /**
  * Handles the selection of time slots in the calendar.
  * This function is triggered when a user selects a time slot. It is used for creating new events.
  * @param {Object} slotInfo - Contains information about the selected time slot.
  */
  const addSchedule = ({ start, end, resourceId }) => {
    setCurrentAction('createSchedule');
    setDialogVisible(true);
    setDialogTitle('Add Details');
    setEventNameLabel('Slot Name');

   
    const formattedTime9AM = formatTime(9);  // Convert to 9 AM
    const formattedTime5PM = formatTime(17); // Convert to 5 PM
    setValue('startTime', formattedTime9AM);
    setValue('endTime', formattedTime5PM);
  };
  const populateFormWithEventData = (event) => {
    setValue('eventDate', event.start);
    setValue('startTime', event.start);
    setValue('endTime', event.end);
    setValue('handler',event.handler);
  };
  const onDoubleClickEvent = (event) => {
    setCurrentAction('updateSchedule');
    setDialogVisible(true);
    setDialogTitle('Update Details');
    setEventNameLabel('Slot Name');
    setSelectedEventId(event._id);
    setEventDate(event.start);
    setStartTime(event.start);
    setEndTime(event.end);
    populateFormWithEventData(event);
  };
  
  const slotStyleGetter = (date) => {
    const startOfDay = moment(date).startOf('day');
    const minutesSinceStartOfDay = moment(date).diff(startOfDay, 'minutes');
    const isTenMinuteInterval = minutesSinceStartOfDay % 30 === 0;
    const isSixtyMinuteInterval = minutesSinceStartOfDay % 60 === 0;
  
    if (isSixtyMinuteInterval) {
      return {
        style: {
        borderBottom: '1px solid #008080',
        },
      };
    } else if (isTenMinuteInterval) {
    return {
    style: {
    borderBottom: '1px solid #00FF00',
    },
    };
    } else {
      return {};
    }
  };
  const formats = {
    timeGutterFormat: (date, culture, localizer) => {
      const hours = localizer.format(date, 'hh A', culture); // Includes AM/PM
      const minutes = localizer.format(date, 'mm', culture);
  
      if (minutes === '00') {
  return `${hours}`;
      }
      return `${minutes}`;
    },
  };

  const onHide = () => {
    reset({
      handler:"",
      eventDate:null
    });
    setDialogVisible(false);
    setCurrentAction("");
    setValue("handler",'');
    };
  const getColumnClass = () => {
    if (dialogVisible) {
      if (currentAction === 'createSchedule' || currentAction === 'updateSchedule') {
        return "col-9";
      }
    }
    return "col-12";
  };

  const getSidebarClass = () => {
    if (dialogVisible) {
      if (currentAction === 'createSchedule' || currentAction === 'updateSchedule') {
        return "col-3";
      }
    }
    return "d-none";
  };
  const handleSelectSlot = ({ start, end, resourceId }) => {
    if (selectedPasteEvent) {
      setShowConfirmationDialog(true);
      setStartTime(start);
      setPasteEventOperative(resourceId);
    }
  }

  const isThisWeekend =  (date) =>{
  
    const dateString = date
    const dayOfWeek = dateString.getDay();
    if (dayOfWeek === 6 || dayOfWeek === 0) {
        return true;
    } else {
        return false;
    } 
  }

  const getWeekendConfirmation = (selectedDate,type)=>{
    if(type === "copy"){
      onPasteCopiedEvent(selectedDate);
    }else if(type === "cut"){
      onPasteEvent(selectedDate);
    }
  }

const onPasteWeekendInfo = (selectedDate , type)=>{
      const isWeekend =  isThisWeekend(selectedDate.selectedDate)
      setCopiedType(type)
      setCopiedDate(selectedDate);
      if(isWeekend){
        setShowConfirmationDialog(false);
        setIsWeekendConfirm(true);
      }else{
        getWeekendConfirmation(selectedDate,type);
      }
}

  const ActionConfirmationDialog = (selectedDate) => (
    <Dialog header={`Modify Schedule`} visible={showConfirmationDialog} onHide={() =>PasteCopiedCancelFunction()}
    footer={
      <>
        <Button label="Cut & Paste" onClick={() =>onPasteWeekendInfo(selectedDate , 'cut')} />
        <Button label="Copy & Paste" onClick={() => onPasteWeekendInfo(selectedDate , 'copy') } />
        <Button label="Cancel" onClick={() => PasteCopiedCancelFunction()} />
      </>
    }>
      <p>Are you sure you want to continue with ?</p>
    </Dialog>
  );
  const onPasteCopiedEvent = async (selectedDate) => {
    if (selectedPasteEvent) {
      const eventId = selectedPasteEvent.slotId ? selectedPasteEvent.slotId : null;
        
      const eventDuration = selectedPasteEvent.end.getTime() - selectedPasteEvent.start.getTime();
      let requestUrl;
      let requestBody;
      const fromTime = new Date(new Date(selectedDate.selectedDate.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds() , 0)));
      const toTime = new Date(fromTime.getTime() + eventDuration);
      const adjustedCutCalendarToTime = new Date(fromTime);
      adjustedCutCalendarToTime.setHours(calendarToTime.getHours(), calendarToTime.getMinutes(), 0, 0);
      if (fromTime < new Date() || toTime < new Date()) {
        setShowConfirmationDialog(false);
        toast.current.show({ 
          severity: 'error', 
          summary: 'Error', 
          detail: 'Cannot paste events to a past time.', 
          life: 3000 
        });
        return;
      }
      if (toTime > adjustedCutCalendarToTime) {
        toast.current.show({ 
          severity: 'error', 
          summary: 'Error', 
          detail: 'Pasted event duration cannot extend beyond the calendar\'s closing time.', 
          life: 3000 
        });
        setSelectedPasteEvent(null); 
        setShowConfirmationDialog(false); 
        return;
      }
        if (selectedPasteEvent.handler) {
        let selectedHandler;
        let hospitalId;
       
        handlers.forEach(handler => {
          if(handler._id === pasteEventOperative){
            selectedHandler=handler._id;
            hospitalId = handler.hospitalId;
          }
        });
          let breakStartDateTime, breakEndDateTime;

          if (breakStartTime) {
            breakStartDateTime = new Date(selectedDate.selectedDate.setHours(...breakStartTime.split(':')));
          }

          if (breakEndTime) {
            breakEndDateTime = new Date(selectedDate.selectedDate.setHours(...breakEndTime.split(':')));
          }

        let dragAndDrop = "true";
        requestBody = {
          fromTime: formatDate.formatDate(new Date(selectedDate.selectedDate.setHours(startTime.getHours(), startTime.getMinutes()))),
          // toTime: formatDate.formatDate(new Date(selectedDate.selectedDate.setHours(cutEvent.end.getHours(), cutEvent.end.getMinutes()))),
          toTime: formatDate.formatDate(toTime),
          handlers: [selectedHandler],
          hospitalId: hospitalId,
          dragAndDrop:dragAndDrop,
          breakStartTime: formatDate.formatDate(breakStartDateTime),
          breakEndTime: formatDate.formatDate(breakEndDateTime),
        };
        if (clinicData) {
          requestBody.clinicId = clinicData;
        }
        requestUrl = `${apiCalls.schedules}`;
      } else {
        console.error('Event does not meet criteria for update');
        return;
      }
      try {
        setIsLoading(true); 
        const response = await fetchMethodRequest('POST', requestUrl, requestBody);
        if (response && response.respMessage === "Schedules created successfully.") {
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Schedule Copied successfully.' });
          setIsWeekendConfirm(false);
        } else {
          console.error('Update failed:', response);
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Copy event.' });
        }
      } catch (error) {
        console.error('Error updating event:', error);
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Copy event.' });
      } finally {
        setIsLoading(false); 
      }
    
    setSelectedPasteEvent(null);
    setShowConfirmationDialog(false);
    getSchedulesData();
    setPasteEventOperative(null);
    setCurrentDateTime({ date: selectedDate.selectedDate, time: { hours: startTime.getHours(), minutes: selectedPasteEvent.start.getMinutes() } });
  }
  };

  const PasteCopiedCancelFunction = () => {
    setShowConfirmationDialog(false);
    setCurrentDateTime({ date:selectedPasteEvent.start, time: { hours: selectedPasteEvent.start.getHours(), minutes: selectedPasteEvent.start.getMinutes() } });
    setSelectedPasteEvent(null);
    getSchedulesData();
  }
  const onPasteEvent = async (selectedDate) => {
    if (selectedPasteEvent) {
        const eventId = selectedPasteEvent.slotId ? selectedPasteEvent.slotId : null;
        
        const eventDuration = selectedPasteEvent.end.getTime() - selectedPasteEvent.start.getTime();

        let requestUrl;
        let requestBody;
        
        const fromTime = new Date(new Date(selectedDate.selectedDate.setHours(startTime.getHours(), startTime.getMinutes())));
        const toTime = new Date(fromTime.getTime() + eventDuration);
        const adjustedPasteCalendarToTime = new Date(fromTime);
        adjustedPasteCalendarToTime.setHours(calendarToTime.getHours(), calendarToTime.getMinutes(), 0, 0);
        if (fromTime < new Date() || toTime < new Date()) {
          setShowConfirmationDialog(false);
          toast.current.show({ 
            severity: 'error', 
            summary: 'Error', 
            detail: 'Cannot paste events to a past time.', 
            life: 3000 
          });
          return;
        }
        if (toTime > adjustedPasteCalendarToTime) {
          toast.current.show({ 
            severity: 'error', 
            summary: 'Error', 
            detail: 'Pasted event duration cannot extend beyond the calendar\'s closing time.', 
            life: 3000 
          });
          setSelectedPasteEvent(null); 
          setShowConfirmationDialog(false); 
          return;
        }
        if (selectedPasteEvent.handler) {
          let selectedHandler;
          let hospitalId;
          let dragAndDrop;
         
          handlers.forEach(handler => {
            if(handler._id === pasteEventOperative){
              selectedHandler=handler._id;
              hospitalId = handler.hospitalId;
            }
          });
          dragAndDrop="true";
          requestBody = {
            fromTime: formatDate.formatDate(new Date(selectedDate.selectedDate.setHours(startTime.getHours(), startTime.getMinutes()))),
            // toTime: formatDate.formatDate(new Date(selectedDate.selectedDate.setHours(cutEvent.end.getHours(), cutEvent.end.getMinutes()))),
            toTime:formatDate.formatDate(toTime),
            handlers: [selectedHandler],
            hospitalId: hospitalId,
            _id: eventId,
            dragAndDrop:dragAndDrop
          };
          if (clinicData) {
            requestBody.clinicId = clinicData;
          }
          requestUrl = `${apiCalls.schedules}/${eventId}`;
        } else {
          console.error('Event does not meet criteria for update');
          return;
        }
  
        try {
          setIsLoading(true); 
          const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
          if (response && response.respMessage === "Schedules updated successfully.") {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Schedule Cut successfully.' });
            setIsWeekendConfirm(false);
          } else {
            console.error('Update failed:', response);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Cut event.' });
          }
        } catch (error) {
          console.error('Error updating event:', error);
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Cut event.' });
        } finally {
          setIsLoading(false); 
        }
      
      setSelectedPasteEvent(null);
      setShowConfirmationDialog(false);
      getSchedulesData();
      setPasteEventOperative(null);
      setCurrentDateTime({ date: selectedDate.selectedDate, time: { hours: startTime.getHours(), minutes: selectedPasteEvent.start.getMinutes() } });
    }
  }; 

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [currentView, setCurrentView] = useState('day');

  const isToday = () => {
    const today = new Date();
    return today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate();
  };
  
  const hightLight =(type)=>{
    // const highlightToday = isToday() && currentView === 'day' && !sessionStorage.calenderViewType
    const highlightToday = isToday() && (viewType === 'today');
    const isDayView = currentView === 'day' && currentView !== 'week' && !highlightToday; 
    const isWeekView = currentView === 'week' && currentView !== 'day' && !highlightToday;
    let isToDate =  isToday() ;
    if(type === 'today' && isToDate
   //  && currentView === 'day'
     && viewType === type
    ){
     return true;
    }else if (type === 'day' && currentView === type && viewType === type ){
     return true; 
    }else if(type === 'week' && currentView === type && viewType === type){
     return true; 
    }
    return false;

 }

 // Function to compare only the date parts
 const areDatesEqual = (newDate, currentDate) => {
  return newDate.getFullYear() === currentDate.getFullYear() &&
       newDate.getMonth() === currentDate.getMonth() &&
       newDate.getDate() === currentDate.getDate();
  }

  const CustomToolbar = ({ label, date, onNavigate, onView }) => {
   


    const navigate = action => {
      onNavigate(action);
    };
    const navigateMonth = (direction) => {
      const newDate = new Date(date);
      newDate.setMonth(date.getMonth() + direction);
      setDate(newDate);
      onNavigate(Navigate.DATE, newDate);
      if (areDatesEqual(newDate, new Date())) {
        setViewType('today');
      } else {
        setViewType('day');
      }
  };

  const navigateWeek = (direction) => {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + (direction * 7));
      setDate(newDate);
      onNavigate(Navigate.DATE, newDate);
      if (areDatesEqual(newDate, new Date())) {
        setViewType('today');
      } else {
        setViewType('day');
      }
  };
    const view = view => {
      onView(view);
      setCurrentView(view);
      setViewType(view);
    };

    const handleDateSelect = (newDate) => {
      setDate(newDate);
      onNavigate(Navigate.DATE, newDate);
      onView(Views.DAY);
      setIsCalendarVisible(false);
      setSelectedDate(newDate);
    };

    // Function to toggle the calendar visibility
    const openCalendar = () => {
      setIsCalendarVisible(!isCalendarVisible);
    };

   
    const formattedLabel = currentView === 'week'
      ? moment(date).format('MMMM YYYY')
      :`${moment(date).format('ddd').charAt(0).toUpperCase() + moment(date).format('ddd').slice(1)} - ${moment(date).format('DD MMM YYYY').toUpperCase()}`;

    const highlightToday = isToday() && currentView === 'day'
    const isDayView = currentView === 'day' && !highlightToday;
    const isWeekView = currentView === 'week';

    const handleTodayClick = () => {
      const today = new Date();
      setDate(today);
      setSelectedDate(today);
      onNavigate(Navigate.TODAY);
      onView(Views.DAY);
      setCurrentView('day');
      setViewType('today')
    };    
  return (

      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
        {selectedPasteEvent &&
            <>
              <button type="button">Copied</button>
              <button type="button" onClick={() => { PasteCopiedCancelFunction() }}>Cancel</button>
            </>
          }
        </span>
        <div className="date-label-container" >
        <button type="button" onClick={() => navigateMonth(-1)}><strong>M</strong></button>
        <button type="button" onClick={() => navigateWeek(-1)}><strong>W</strong></button>
        <button type="button" className='navigation-button' 
        // onClick={() => navigate(Navigate.PREVIOUS)}
        onClick={() => {
          let newDate = new Date(date);
          newDate.setDate(newDate.getDate() - 1);
          setDate(newDate);
          setSelectedDate(newDate);
          onNavigate(Navigate.PREVIOUS);
          if (areDatesEqual(newDate, new Date())) {
            setViewType('today');
          } else {
            setViewType('day');
          }
          // setViewType('day');
        }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <span className="rbc-toolbar-label" onClick={openCalendar}>
            {formattedLabel}
            <FontAwesomeIcon icon={faCaretDown} className='down-arrow'/>
          </span>
          
          {isCalendarVisible &&
            <div className="calendar-popup">
              <PrimeCalendar
                className='toolbar-calendar'
                value={date}
                onSelect={(e) => handleDateSelect(e.value)}
                inline
              />
            </div>
          }
          <button type="button" className='navigation-button' 
          // onClick={() => navigate(Navigate.NEXT)}
          onClick={() => {
            let newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);
            setDate(newDate);
            setSelectedDate(newDate);
            onNavigate(Navigate.NEXT);
            if (areDatesEqual(newDate, new Date())) {
              setViewType('today');
            } else {
              setViewType('day');
            }
            // setViewType('day');
          }}
          >
          <FontAwesomeIcon icon={faAngleRight} />
          </button>
          <button type="button" onClick={() => navigateWeek(1)}><strong>W</strong></button>
          <button type="button" onClick={() => navigateMonth(1)}><strong>M</strong></button>
          <button type="button" onClick={() => navigateMonth(3)}><strong>3</strong></button>
         <button type="button" onClick={() => navigateMonth(4)}><strong>4</strong></button>
         <button type="button" onClick={() => navigateMonth(6)}><strong>6</strong></button>
        </div>
        <span className="rbc-btn-group">
        {/* <button type="button" onClick={handleTodayClick} className={clsx({ 'rbc-active': highlightToday })}>Today</button>
        <button type="button" onClick={() => view(Views.DAY)} className={clsx({ 'rbc-active': isDayView })}>Day</button>
        <button type="button" onClick={() => view(Views.WEEK)} className={clsx({ 'rbc-active': isWeekView })}>Week</button> */}

          <button type="button" onClick={handleTodayClick} className={clsx({ 'rbc-active': hightLight('today') })}>Today</button>
          <button type="button" onClick={() => view(Views.DAY)} className={clsx({ 'rbc-active': hightLight('day') })}>Day</button>
          <button type="button" onClick={() => view(Views.WEEK)} className={clsx({ 'rbc-active': hightLight('week') })}>Week</button>
        </span>
      </div>
    );
  };
  

  const checkForWeekends = (startDate, endDate) => {
    let currentDate = new Date(startDate);
    endDate = new Date(endDate);

    while (currentDate <= endDate) {
      if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
        return true; 
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return false;
  };


  const createPayload = (formData, includeWeekends) => {
    let hospitalId;
    if (formData.handler && formData.handler.length > 0) {
      handlers.forEach(handler => {
        if (handler._id === formData.handler[0]) {
          hospitalId = handler.hospitalId;
        }
      });
    }
    let fromTime = `${formData.dateRange[0]}T${formData.startTime}:00`;
    let toTime = `${formData.dateRange[1]}T${formData.endTime}:00`;
    let fromDate = moment(formData.dateRange[0]);
    let toDate = moment(formData.dateRange[1]);

    let payload = {
      handlers: formData.handler,
      hospitalId: hospitalId,
      fromTime: formatDate.formatDate(fromTime),
      toTime: formatDate.formatDate(toTime),
      fromDate,
      toDate,
    };
    if (breakStartTime) {
      let breakStartDate = `${formData.dateRange[0]}T${breakStartTime}:00`;
      payload.breakStartTime = formatDate.formatDate(breakStartDate);
    }
  
    if (breakEndTime) {
      let breakEndDate = `${formData.dateRange[0]}T${breakEndTime}:00`;
      payload.breakEndTime = formatDate.formatDate(breakEndDate);
    }

    if (clinicData) {
      payload.clinicId = clinicData;
    }
    if (!includeWeekends) {
      let startDate = new Date(formData.dateRange[0]);
      let endDate = new Date(formData.dateRange[1]);
      let weekendDates = [];
  
      for (let dt = new Date(startDate); dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        if (dt.getDay() === 0 || dt.getDay() === 6) {
          weekendDates.push(new Date(dt).toISOString().split('T')[0]);
        }
      }
  
      
      if (weekendDates.length > 0) {
        payload.weekendDates = weekendDates;
      }
    }
  
    return payload;
  };
  
  const submitSchedule = (formData, includeWeekends) => {
    setIsLoading(true);
    const payload = createPayload(formData, includeWeekends);
    
    fetchMethodRequest('POST', apiCalls.schedules, payload)
      .then(response => {
        if (response.respMessage) {
          setIsLoading(false);
          setShowWeekendConfirmation(false);
          onHide(); 
          getSchedulesData();
          showToasterMessage(response.respMessage,'success');
        } else if (response.errorMessage) {
          showToasterMessage(response.errorMessage,'error');
        }
      })
      .catch(error => {
        console.error('Error processing request:', error);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An unexpected error occurred.',
          life: 3000,
          style: {color: 'red'}
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const getTimeFromDate = (time, baseDate) => {
    return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), time.getHours(), time.getMinutes());
  };

  const parseTime = (timeString, baseDate) => {
    if ((/^\d{2}:\d{2}$/).test(timeString)) {
      const [hours, minutes] = timeString.split(':').map(Number);
      return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hours, minutes);
    } else {
      const isoDate = new Date(timeString).toISOString();
      return isoDate
    }
  };

  // Function to process each time input independently
  const processTimeInput = (timeInput, baseDate) => {

    if (typeof timeInput === 'string') {
      return parseTime(timeInput, baseDate);
    } else if (timeInput instanceof Date) {
      return getTimeFromDate(timeInput, baseDate);
    } else {
      console.error("Invalid time input format");
      return null;
    }
  };
  const handleFormSubmit = (data) => {
    if(currentAction === "createSchedule"){
    setWekendDatesInformation(data);
    const includesWeekend = checkForWeekends(data.dateRange[0], data.dateRange[1]);
    if (includesWeekend) {
      setTempFormData(data);
      setShowWeekendConfirmation(true);
    } else {
      submitSchedule(data, false);
    }
  }else if (currentAction === "updateSchedule") {
    const includesWeekend = checkForWeekends(data.eventDate, data.eventDate);
    if (includesWeekend) {
      setTempFormData(data);
      setShowWeekendConfirmationForUpdate(true);
    } else {
      updateSchedule(data);
    }
  }
};

const updateSchedule = (data) => {
  const parseTime = (timeString, baseDate) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hours, minutes);
  };
  // let start = typeof data.startTime === 'string' ? parseTime(data.startTime, data.eventDate) : data.startTime;
  // let end = typeof data.endTime === 'string' ? parseTime(data.endTime, data.eventDate) : data.endTime;
  const startDate = new Date(data.eventDate);
  const endDate = new Date(data.eventDate);
  let start = processTimeInput(data.startTime, startDate);
  let end = processTimeInput(data.endTime, endDate);

  const formattedDate = formatDate.formatDate(start);
  const fromTimePart = formatDate.formatDate(start);
  const toTimePart = formatDate.formatDate(end);
  const modifiedFromTime = formattedDate.substring(0, 10) + fromTimePart.substring(10);
  const modifiedToTime  = formattedDate.substring(0, 10) + toTimePart.substring(10);
  const matchingSchedule = schedules.find(schedule => schedule._id === selectedEventId);
  let handler;
  if (typeof(data.handler)==='string'){
    handler=matchingSchedule.handlers && matchingSchedule.handlers._id;
  }else{
    handler = data.handler._id;
  }
  let hospitalId= matchingSchedule ? matchingSchedule.hospitalId : undefined;
  const payload = {
    handlers: [handler],
    // fromTime: formatDate.formatDate(start),
    // toTime: formatDate.formatDate(end),
    fromTime:modifiedFromTime,
    toTime:modifiedToTime,
    hospitalId:hospitalId,
    _id:selectedEventId
  };
  if (clinicData) {
    payload.clinicId = clinicData;
  }
  const requestUrl = `${apiCalls.schedules}/${selectedEventId}`;

  fetchMethodRequest('PUT', requestUrl, payload)
    .then(response => {
      if(response.respCode){
        getSchedulesData();
        setIsLoading(false);
        setDialogVisible(false);
        setValue('handler', '');
        showToasterMessage(response.respMessage,'success');
      }else {
        showToasterMessage(response.errorMessage,'error');
      }
    })
    .catch(error => {
      console.error('Error updating schedule:', error);

    })
}


const WeekendConfirmationDialogForUpdate = () => (
  <Dialog
    header="Confirm Weekend Schedule"
    visible={showWeekendConfirmationForUpdate}
    onHide={() => setShowWeekendConfirmationForUpdate(false)}
    footer={
      <>
        <Button label="Yes" onClick={() => {
          updateSchedule(tempFormData);
          setShowWeekendConfirmationForUpdate(false);
        }} />
        <Button label="No" onClick={() => {
          setShowWeekendConfirmationForUpdate(false);
        }} />
      </>
    }>
    <p>Are you sure you want to update the schedule on a weekend?</p>
  </Dialog>
);

 // Check if both dates are weekends
// function areWeekends(date1, date2) {

function areWeekends(startDate, endDate) {
  const fromDate = new Date(startDate);
  const toDate = new Date(endDate);

  const differenceInMs = Math.abs(toDate - fromDate);
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
  if (differenceInDays <= 1) {
    const weekends = [0, 6]; // Sunday (0) and Saturday (6)
    function isWeekend(date) {
      return weekends.includes(date.getDay());
    }
    const bothAreWeekends = isWeekend(fromDate) && isWeekend(toDate);
    return bothAreWeekends;
  } else {
    return false;
  }
}

  const handleWeekendConfirmation = (includeWeekends) => {

    const startDateRange = new Date(wekendDatesInformation.dateRange[0]); 
    const endDateRange = new Date(wekendDatesInformation.dateRange[1]); 
    const result = areWeekends(startDateRange, endDateRange); 
   
    const weekendValidation= includeWeekends || (!includeWeekends && !result)
    if (tempFormData &&  weekendValidation) {
      submitSchedule(tempFormData, includeWeekends);
    }else{
      setShowWeekendConfirmation(false);
    }
    setTempFormData(null);
  };
  
  const confirmDeletion = (event) => {
    setEventToDelete(event);
    setShowDeleteConfirmation(true);
  };
  const DeleteConfirmationDialog = () => (
    <Dialog
      header="Confirm Deletion"
      visible={showDeleteConfirmation}
      draggable={false}
      onHide={() => setShowDeleteConfirmation(false)}
      footer={
        <div>
          <Button label="No" onClick={() => setShowDeleteConfirmation(false)} />
          <Button label="Yes" onClick={() => handleDeleteConfirmed(eventToDelete)} />
        </div>
      }
    >
      <p>Are you sure you want to delete this event?</p>
    </Dialog>
  );


  const handleDeleteConfirmed = (event) => {
    if (event) {
      let requestUrl;
      requestUrl = `${apiCalls.schedules}/${event._id}`;

      fetchMethodRequest('DELETE', requestUrl)
        .then(response => {
          if (response && response.respMessage==="Schedules delete successfully.") {
            setEvents(events.filter(event => event._id !== event._id));
            onHide();
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Schedules delete successfully.' });
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete event.' });
          }
        })
        .catch(error => {
          console.error('Error deleting event:', error);
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error occurred while deleting event.' });
        })
        .finally(() => {
          setSelectedPasteEvent(null);
          setShowDeleteConfirmation(false);
          setEventToDelete(null);
          getSchedulesData();
        });
    }
  };
  const EventComponent = ({ event }) => {
    const target = useRef(null);
    const onSelectEventAction = (event) => {
      setSelectedPasteEvent(event);
    };

    return (
      <div className="custom-event" ref={target}>
        <div className="event-icons">
          <FontAwesomeIcon icon={faTrash} className='icon-circle' onClick={() => confirmDeletion(event)} />
          <FontAwesomeIcon icon={faCopy} data-toggle="tool-tip" title="Copy" className='icon-circle' onClick={() => onSelectEventAction(event)} />

        </div>
      </div>
    );
  };


  /**
   * Handles the resizing of events in the calendar.
   * Updates the event's start and end times based on the new size after resizing.
   * @param {Object} event - The event object with the updated size data.
   */
  const onEventResize = ({ event, start, end }) => {

    if (end < new Date()) {
      toast.current.show({ 
        severity: 'error', 
        summary: 'Error', 
        detail: 'Cannot move events to a past time.', 
        life: 3000 
      });
      return;
    }
    const adjustedResizeCalendarToTime = new Date(start);
    adjustedResizeCalendarToTime.setHours(calendarToTime.getHours(), calendarToTime.getMinutes(), 0, 0);

    if (end > adjustedResizeCalendarToTime) {
      toast.current.show({ 
        severity: 'error', 
        summary: 'Error', 
        detail: 'Event duration cannot extend beyond the calendar\'s closing time.', 
        life: 3000 
      });
      return;
    }

    setResizeEvent({ event, start, end });
    setIsResizeConfirmDialogVisible(true);
    setCurrentDate(start);
    setCurrentDateTime({ date: start, time: { hours: start.getHours(), minutes: start.getMinutes() } });
  };
  
  /**
   * Renders a confirmation dialog for confirming event resize.
   * @returns {JSX.Element} The resize confirmation dialog component.
   */
  const ResizeConfirmDialog = () => (
    <Dialog
      header="Confirm Event Resize"
      visible={isResizeConfirmDialogVisible}
      draggable={false}
      onHide={() => setIsResizeConfirmDialogVisible(false)}
      footer={
        <div>
          <Button label="No" onClick={() => setIsResizeConfirmDialogVisible(false)} />
          <Button label="Yes" onClick={confirmResizeChange} />
        </div>
      }
    >
      <p>Do you want to update the event time?</p>
    </Dialog>
  );
  /**
   * Confirms the event resize change and updates the event data accordingly.
   */
  const confirmResizeChange = async () => {
    if (resizeEvent) {
      const updatedEvent = events.find(e => e._id === resizeEvent.event._id);
      if (updatedEvent) {
        const eventId = updatedEvent._id;

        let requestUrl;
        let requestBody;
        let resize = "true";

        if (updatedEvent.handler) {
          let hospitalId, handler;
          schedules.forEach(schedule => {
            if (schedule._id === updatedEvent._id) {
              hospitalId = schedule.hospitalId;
              handler = schedule.handlers._id;
            }
          });

          requestBody = {
            _id: eventId,
            fromTime: formatDate.formatDate(resizeEvent.start),
            toTime: formatDate.formatDate(resizeEvent.end),
            hospitalId: hospitalId,
            handlers: [handler],
            resize:resize
          };
          if (clinicData) {
            requestBody.clinicId = clinicData;
          }
          requestUrl = `${apiCalls.schedules}/${updatedEvent._id}`;
        } else {
          console.error('Event does not meet criteria for either schedules or appointments update');
          return;
        }

        try {
          setIsLoading(true);
          const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
          if (response && response.respMessage==="Schedules updated successfully.") {
            // Update state to reflect the changes in UI
            const updatedEvents = events.map(evt => {
              if (evt._id === resizeEvent.event._id) {
                return { ...evt, start: resizeEvent.start, end: resizeEvent.end };
              }
              return evt;
            });
            setEvents(updatedEvents);
            toast.current.show({ severity: 'success', summary: 'Event Updated', detail: 'Event time has been updated successfully', life: 3000 });
          } else {
            console.error('Update failed:', response);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update schedule.' });
          }
        } catch (error) {
          console.error('Error updating event:', error);
        } finally {
          setIsLoading(false);
          getSchedulesData();
          setIsResourceChanged(false);
        }
      }
    }
    setIsResizeConfirmDialogVisible(false);
    setResizeEvent(null);
  };

  const createDefaultTime = () => {
    const defaultDateTime = new Date();
    defaultDateTime.setHours(12, 0, 0, 0);
    return defaultDateTime;
  };

  const onEventDrop = ({ event, start, end ,resourceId}) => {
    if (start < new Date() || end < new Date()) {
      toast.current.show({ 
        severity: 'error', 
        summary: 'Error', 
        detail: 'Cannot move events to a past time.', 
        life: 3000 
      });
      return;
    }

    const adjustedDropCalendarToTime = new Date(start);
    adjustedDropCalendarToTime.setHours(calendarToTime.getHours(), calendarToTime.getMinutes(), 0, 0);
    
    if (end > adjustedDropCalendarToTime) {
      toast.current.show({ 
        severity: 'error', 
        summary: 'Error', 
        detail: 'Event cannot end after the calendar\'s closing time.', 
        life: 3000 
      });
      return;
    }
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const updatedEvents = [...events];
    updatedEvents[idx] = { ...event, start, end };
    setDraggedEvent({ event, start, end ,resourceId });
    setIsDragConfirmDialogVisible(true);
    setCurrentDate(start);
    setCurrentDateTime({ date: start, time: { hours: start.getHours(), minutes: start.getMinutes() } });

    const isResourceChanged = event.resourceId !== resourceId;
    setIsResourceChanged(isResourceChanged);


  };
  /**
   * Renders a confirmation dialog for confirming changes made by dragging events.
   * It prompts the user to confirm or cancel the changes made by dragging an event to a new time slot.
   * @returns {JSX.Element} The drag confirmation dialog component.
   */
    const DragConfirmDialog = () => (
    <Dialog
      header="Confirm Event Change"
      visible={isDragConfirmDialogVisible}
      draggable={false}
      onHide={() => setIsDragConfirmDialogVisible(false)}
      footer={
        <div>
          <Button label="No" onClick={() => setIsDragConfirmDialogVisible(false)} />
          <Button label="Yes" onClick={confirmDragChange} />
        </div>
      }
    >
{isResourceChanged ? (
        <p>Change Operatory and timings?</p>
      ) : (
        <p>Do you want to update the event time?</p>
      )}
    </Dialog>
  );
  /**
   * Confirms the changes made by dragging an event and updates the event data accordingly.
   * It is called when the user confirms the changes in the drag confirmation dialog.
   */
  const confirmDragChange = async () => {
    if (draggedEvent) {
        const updatedEvent = events.find(e => e._id === draggedEvent.event._id);
          if (updatedEvent) {
            const eventId = updatedEvent._id;

            let requestUrl;
            let requestBody;

            if (updatedEvent.handler) {
                let selectedHandler;
                let hospitalId;
                let dragAndDrop;
                schedules.forEach(schedule => {
                    if (schedule._id === updatedEvent._id) {
                        hospitalId = schedule.hospitalId;
                    }
                });
                
                handlers.forEach(handler => {
                  if(handler._id === draggedEvent.resourceId){
                    selectedHandler=handler._id;
                  }
                });
                dragAndDrop = "true";

                requestBody = {
                    _id: eventId,
                    fromTime: formatDate.formatDate(draggedEvent.start),
                    toTime: formatDate.formatDate(draggedEvent.end),
                    handlers: [selectedHandler],
                    hospitalId: hospitalId,
                    dragAndDrop:dragAndDrop

                };
                if (clinicData) {
                  requestBody.clinicId = clinicData;
                }
                requestUrl = `${apiCalls.schedules}/${updatedEvent._id}`;
            } else {
                console.error('Event does not meet criteria for either slots or appointments update');
                return;
            }

            try {
                setIsLoading(true);
                const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
                if (response && response.respMessage === "Schedules updated successfully.") {
                    toast.current.show({ severity: 'success', summary: 'Event Moved', detail: 'Event has been moved successfully', life: 3000 });
                    setDialogVisible(false);
                } else {
                    // Show an error message if the update is unsuccessful or the response message is different
                    console.error('Update failed:', response);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update schedule.', life: 3000 });
                }
            } catch (error) {
                console.error('Error updating event:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while updating the event.', life: 3000 });
            } finally {
                setIsLoading(false);
                getSchedulesData();
                            }
        }
    }
setIsDragConfirmDialogVisible(false);
    setDraggedEvent(null);
};

  const handleNavigate = (newDate) => {
    setIsLoading(true);
    setCurrentDate(newDate);

     setTimeout(() => {
      setIsLoading(false); // Reset loading state after data is fetched
    }, 1000); // Simulate delay
  };

  const onTabChange = (e) => {
    if (e.index !== 2) 
    setActiveIndex(e.index);
  };
  
  //get Weekend confirmation Modal
  const getWeekendConfirmModal = () =>{
  return  <WeekendConfirmModal  
    header="Create Schedules"
    isOpenWeekendModal = {isWeekendConfirm}
    label="Are you sure you want to create schedules for weekends?"
    closeWeekendModal={()=>setIsWeekendConfirm(false)}
    confirm = {()=>getWeekendConfirmation(copiedDate,copiedType)}
    />
  }
  return (
    <div className='schedule-calendar'>
      <Toast ref={toast} />
       {isLoading && (
        <div className="spinner-container">
          <Loader loader={isLoading}/>
          </div>
      )}
      <TabView onTabChange={(e) => onTabChange(e)} activeIndex={activeIndex} className='Tab-view bg-white p-2'>
      <TabPanel header={<FontAwesomeIcon icon={faCalendarCheck} className='Tab-Panel-Icon' />}>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        {!dialogVisible && <Button label="Add Schedule" onClick={(e) => addSchedule(e)} className='appointment-submit-btn' />}
      </div> */}
      {/* {resources.length === 0 && (
          <div style={{ color: 'red', textAlign: 'right', marginTop: '20px' }}>
            No Operatories. Please create Operatories to manage the calendar.
          </div>
        )} */}
      <div className='row'>
        <div className={getColumnClass()}>
          <DnDCalendar 
            defaultDate={currentDate}
            scrollToTime={scrollTime}
            defaultView="day"
            events={events}
            localizer={localizer}
            onEventDrop={onEventDrop}
              //   tooltipAccessor={false}
            onEventResize={onEventResize}
            dayLayoutAlgorithm="no-overlap"
            {...(timesAreSet ? { min: calendarFromTime, max: calendarToTime } : {})}
            onDoubleClickEvent={onDoubleClickEvent}
            resources={resources}
            //   resizable={true}
            onNavigate={handleNavigate}
            selectable={true}
            onSelectSlot={handleSelectSlot}
            resourceTitleAccessor="resourceTitle"
            resourceIdAccessor="resourceId"
            //   eventPropGetter={eventStyleGetter}
            step={30}
            timeslots={2}
            formats={formats}
            slotPropGetter={slotStyleGetter}
            components={{
              event: EventComponent,
              toolbar: CustomToolbar

            }}
            views={['day', 'week']}
          />
        </div>
        <div className={getSidebarClass()}>
          <div className='calendar-sidebar'>
            <div className="calendar-form-container">
              <div class='d-flex schedule-dialog-header'>
                <div>
                  <h1 class="calendar-form-header">{dialogTitle}</h1>
                </div>
                <button class="close-icon" onClick={onHide}>
                  X
                </button>
              </div>
                  <form onSubmit={handleSubmit(handleFormSubmit)} className="calendar-form">
                    <div>
                      {currentAction === 'createSchedule' && (
                    <CreateSlotModal control={control} currentAction={currentAction} getValues={getValues} errors={errors} setValue={setValue}/>
                      )}
                    </div>
                    <div>
                      {(currentAction === 'updateSchedule') && (
                        <UpdateSlotModal
                          control={control}
                          eventDate={eventDate}
                          startTime={startTime}
                          endTime={endTime}
                          getValues={getValues}
                          setValue={setValue}
                        />
                      )}
                    </div>
                      {currentAction === 'createSchedule' && (
                  <div class="schedule-footer">
                        <Button label="Cancel" onClick={onHide}  />
                        <Button label="Submit" type="submit" className='schedule-submit-btn' />
                  </div>
                      )}

                      {currentAction === 'updateSchedule' && (
                  <div class="schedule-footer">
                    <Button label="Cancel" onClick={onHide} />
                     <Button label="Update" type="submit" className='schedule-submit-btn' />
                  </div>
                      )}

                  </form>
            </div>
          </div>
        </div>
      </div>
      {showWeekendConfirmation && (
        <Dialog
          header="Create Schedules"
          visible={showWeekendConfirmation}
          onHide={() => setShowWeekendConfirmation(false)}
          footer={
            <>
              <Button label="Yes" onClick={() => {
                handleWeekendConfirmation(true);
              }} />
              <Button label="No" onClick={() => {
               handleWeekendConfirmation(false);
              }} />
            </>
          }>
          <p>Are you sure you want to create schedules for weekends?</p>
        </Dialog>
      )}
      {showWeekendConfirmationForUpdate && WeekendConfirmationDialogForUpdate()}
      {isWeekendConfirm ? getWeekendConfirmModal() : null}
      {isDragConfirmDialogVisible && DragConfirmDialog()}
      {isResizeConfirmDialogVisible && ResizeConfirmDialog()}
      <DeleteConfirmationDialog />
      <ActionConfirmationDialog selectedDate={selectedDate}/> 
        </TabPanel>
      <TabPanel header={<FontAwesomeIcon icon={faListOl} className='Tab-Panel-Icon'/>}>
          {/* <SchedulesDataTableComponent/>   */}
          <Schedules />
        </TabPanel>
        {resources.length > 0 && (
          <TabPanel
            header={
              <div>
                {!dialogVisible && activeIndex !== 1 && <Button label="Add Schedule" onClick={(e) => addSchedule(e)} />}
              </div>
            }
            className='add-schedules'
          >
        {/* Content of the tab panel */}
      </TabPanel>
      )}
      {resources.length === 0 && (
        <TabPanel
        header={
          <div style={{ color: 'red', textAlign: 'right', marginTop: '20px' }}>
          No Operatories. Please create Operatories to manage the calendar.
        </div>
        }
        className='add-schedules'
      >
        {/* Content of the tab panel */}
      </TabPanel>
         
        )}
      </TabView>
    </div>
  );
});
export default ScheduleCalendar;
