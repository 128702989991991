import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import DataTables from "../../CommonDataTable/DataTable";
import apiCalls from "../../../../config/apiCalls";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import Loader from "../../../App/Loader";
import { useParams } from "react-router";
import ViewInventory from "./ViewInventory";
import CustomModal from "../../CommonModals/CustomModal";
import Cart from "./Cart";
import { Badge } from 'antd';
import CartIcon from 'mdi-react/CartIcon';

// config file

const Inventories = (props) => {
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cartCount, setCartCount] = useState(0);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Inventories");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);



  useEffect(() => {
    getCartCounts();
  },[cartCount])



  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };


  /**
   * @description set the products count, that show in the cart badge
   */
  function getCartCounts (){
    return fetchMethodRequest("GET", apiCalls.getCartCounts).then(response => response?.count && setCartCount(response.count));
  }


  /**
   * @param {*} item 
   * @returns Custom "Add To OrderList" button
   */
  const addToCartButton = (item) => {
    // console.log("ITEM", item);
    return (
        <div className="add__to__cart d-flex align-items-center justify-content-center gap-3 rounded w-50" onClick={() => addToCart(item)}>
          <i className={/*item?.item?.isProductInCart ? "pi pi-verified": */ "pi pi-cart-plus"} style={{color: "#2563eb", fontWeight: "500"}}></i>
          <span style={{fontSize: ".8rem", fontWeight: "bolder", color:"#71717a"}}> Add To <br/> OrderList
          {/* {item?.item?.isProductInCart ? (<>Go to <br /> OrderList</>) : 
          (<>Add To <br /> OrderList</>)} */}
            {/* Add To <br/>OrderList */}
            </span>
        </div>
    )
  }

  /**
   * @description add the product to cart 
   * @param {*} item 
   * @param {*} type 
   */
  const addToCart = async (item, type = "add") => {
    setIsLoading(true);
    const addToCartBody = _.pick({ ...item?.item, quantity: 1, type }, ["itemID", "itemName", "price", "imageUrl", "quantity", "type"])
    fetchMethodRequest("POST", apiCalls.addToCart, addToCartBody).then(resp => {
      if(resp?.errorCode && resp?.errorMessage) showToasterMessage(resp.errorMessage, "error");
      else showToasterMessage(resp.respMessage, "success");
      setIsLoading(false);
      console.log("dataTableRef.current?.filterCriteria", dataTableRef.current)
      dataTableRef.current?.setFilterCriteria(prev => { 
        dataTableRef.current?.getDataFromServer(prev);
        return prev
       })
      getCartCounts();
    });
  }

  /**
   * @returns tableFields
   */
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: false,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "itemID",
        fieldName: "itemID",
        type: "text",
        placeholder: "Item ID",
        value: "",
        label: "Item ID",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Item ID",
        derivedValue: "itemID=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "itemID",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "itemID",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "left",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: false,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: false,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: false,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: false,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: false,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "productName",
        fieldName: "productName",
        type: "text",
        placeholder: "Product Name",
        value: "",
        label: "Product Name",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Product Name",
        derivedValue: "productName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "itemName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "quantity",
        fieldName: "quantity",
        type: "number",
        placeholder: "Quantity",
        value: "",
        label: "Quantity",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        header: "Quantity",
        derivedValue: "quantity=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "quantity",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "totalQuantity",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        header: "Price",
        derivedValue: "price=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "total",
        fieldName: "total",
        type: "number",
        placeholder: "Total",
        value: "",
        label: "Total",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Total",
        derivedValue: "total=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "total",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "totalPrice",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "actions",
        fieldName: "Actions",
        type: "text",
        customColumn: addToCartButton,
        placeholder: "Actions",
        value: "",
        label: "Actions",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Actions",
        derivedValue: "Actions=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "Actions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "actions",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "customColumn",
        style: {
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },
        tDisplay: true,
      },
    ];
    return data;
  };

  /**
   * @returns formFields
   */
  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "itemID",
        fieldName: "itemID",
        type: "text",
        placeholder: "Item ID",
        value: "",
        label: "Item ID",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "itemID=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "itemID",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: true,
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: true,
        filter: true,
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "productName",
        fieldName: "productName",
        type: "text",
        placeholder: "Product Name",
        value: "",
        label: "Product Name",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        derivedValue: "price=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "quantity",
        fieldName: "quantity",
        type: "number",
        placeholder: "Quantity",
        value: "",
        label: "Quantity",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        derivedValue: "quantity=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "quantity",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "total",
        fieldName: "total",
        type: "number",
        placeholder: "Total",
        value: "",
        label: "Total",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "total=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "total",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };


  const openFormModal =  () => {
    setIsOpenFormModal(true);
  }
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
    getCartCounts();
  };


  /**
   * @description this function refreshes the cartcount and inventory data in the table
   */
  const refresh = () => {
    dataTableRef.current?.setFilterCriteria(prev => { 
      dataTableRef.current?.getDataFromServer(prev);
      return prev
     })
    getCartCounts();
  }



  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  /**
   * @description callback function to add the custom actions
   * @returns 
   */
  const customActions = () => {
    return (
      <div className="ms-3" onClick={openFormModal}>
          <div className="d-flex justify-content-center p-2">
            <Badge count={cartCount} color={"#f97316"} style={{fontWeight:"bold"}} showZero>
              <CartIcon color={"#0e4768"} size={32}/>
            </Badge>
          </div>
      </div>
    );
  }

  return (
    <span>
      <Loader loader={isLoading} />
      <DataTables
        onRef={(ref) => (dataTableRef.current = ref)}
        {...props}
        getTableFields={getTableFields}
        formFields={getFormFields}
        addRequired={isEditPermission}
        editRequired={isEditPermission}
        deleteRequired={isEditPermission}
        viewRequired={isEditPermission}
        exportRequired={isEditPermission}
        sample={isEditPermission}
        printRequried={true}
        actionsTypes={actionTypes}
        settingsRequired={true}
        filterRequired={false}
        gridRequried={true}
        exportToCsv={true}
        dateSearchRequired={false}
        searchInDateRangeField={""}
        setData={setData}
        editSelectedRecord={editSelectedRecord}
        addSelectedRecord={addSelectedRecord}
        viewSelectedRecord={viewSelectedRecord}
        sampleFilePath={filePath.inventories}
        globalSearch={"itemID/productName/price/quantity/total"}
        displayName="Inventories"
        type="Inventories"
        routeTo={apiCalls.inventories}
        displayViewOfForm="screen"
        sideFormLeftOrRight=""
        apiResponseKey={"inventories"}
        apiUrl={apiCalls.inventories}
        selectedId={params.id}
        displayActionsInTable={"actions"}
        disableActionDropdown={true}
        customActions={customActions}
        renderViewScreenComponent={(props) => <ViewInventory {...props} addToCart={addToCart} />}
      />
      <CustomModal visible={isOpenFormModal} onHide={closeFormModal} >
        <Cart closeFormModal={closeFormModal} addToCart={addToCart} refresh={refresh} />
      </CustomModal>
    </span>
  );
};

export default Inventories;
