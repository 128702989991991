import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import fetchMethodRequest from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import DataTable from "../../CommonDataTable/DataTable";
import { ImageComponent } from "./ViewInventory";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import OrderListIcon from "mdi-react/InvoiceIcon"
import formatDate from "../../../UI/FormatDate/formatDate";
import configImages from "../../../../config/configImages";
const Cart = ( { closeFormModal, addToCart, refresh }) => {

  const cartTableRef = useRef(null);
  const [products, setProducts] = useState(null);

  useEffect(() => {
    fetchMethodRequest("GET", apiCalls.getCart).then(response => setProducts(response?.carts) )
  },[products])

  /**
   * 
   * @param {*} item 
   * @returns productColumn
   */
  const productColumn = (item) => {
      return (
          <div style={{fontFamily: `"Inter", sans-serif`, maxWidth: "500px"}}>
              <div className="d-flex align-items-center gap-3">
                  {/**@Image */}
                  <ImageComponent imageUrl={item?.item?.imageUrl} width={"60px"} height={"60px"}/>
                  <div className="d-flex flex-column">
                      <span className="fw-bold flex-wrap" style={{color:"#475569"}}>{item?.item?.itemName}</span>
                      <span style={{color: "#6b7280", fontSize: ".9rem"}}>#{item?.item?.itemID}</span>
                  </div>
              </div>
          </div>
      )
  }

  /**
   * @description do the apiCall to add product in cart, after successfull response fetch the product again
   * @param {*} item 
   * @param {*} type 
   * @param {*} event 
   * @returns 
   */
  const cartAction = (item, type, event) => {
    if(type === "remove" && item?.item?.quantity === 1) {
      showDeletePopOver(event, item);
      return;
    }
    addToCart(item, type).then(resp => 
      // cartTableRef.current?.getDataFromServer()
      setTimeout(() => cartTableRef.current?.getDataFromServer(),700)
    )
  }

  /**
   * @description delete the product from cart and fetch again
   * @param {*} item 
   */
  const deleteCart = (item) => {
    if(item?.item?._id){
      fetchMethodRequest("DELETE", `${apiCalls.deleteCart}/${item.item._id}`).then(response =>  {
        if(response?.errorCode && response.errorMessage) showToasterMessage(response.errorMessage, "error");
        else showToasterMessage(response.respMessage, "success");
        cartTableRef?.current?.getDataFromServer();
        refresh();
        cartTableRef?.current?.setAllUsersData(prev => {
          setProducts(prev)
          return prev;
        })
      })
      
    }
  }


  /**
   * @description show delete popover to show the alert to user
   * @param {*} event 
   * @param {*} item 
   */
  const showDeletePopOver = (event, item) => {
    confirmPopup({
      target: event.currentTarget,
      message: (
        <div className="flex flex-column justify-content-center align-items-center gap-2">
          <CartCircle>
          <i className="pi pi-shopping-cart"></i>
          </CartCircle>
          {/* <span className="cart__circle"><i className="pi pi-shopping-cart"></i></span> */}
          <span className="fw-bold" style={{fontSize: "1rem"}}>Are you sure you want to remove this product from your cart?</span>
        </div>
      ),
      defaultFocus: 'accept',
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      acceptClassName: "delete_cart__confirmation",
      accept: () => deleteCart(item),
      reject:() => { console.log("REJECT")}
    });
  }

  /**
   * 
   * @param {*} item 
   * @returns custom totalColumn (JSX)
   */
  const totalColumn = (item) => {
    return (
      <>
      <div className="d-flex justify-content-center align-items-center mx-4">
        <span className="ms-auto">{item?.item?.totalPrice}</span>
        <span className="ms-auto" onClick={(event) => showDeletePopOver(event, item)}><i className="pi pi-trash" style={{fontSize: "0.8rem", color: "#dc2626"}}></i></span>
      </div>
      </>
    )
  }

  /**
   * 
   * @param {*} item 
   * @returns custom quantityColumn (JSX) 
   */
  const quantityColumn = item => {
    return (
      <div className="d-flex align-items-center justify-content-center gap-4">
        <span onClick={(e) => cartAction(item, "remove", e)}><i className="pi pi-minus" style={{fontSize: ".8rem", color: "#dc2626", fontWeight: "bold"}}></i></span>
        <span className="fw-bold" style={{color: '#52525b'}}>{item?.item?.quantity}</span>
        <span onClick={() => cartAction(item)}><i className="pi pi-plus" style={{fontSize: ".8rem", color: "#059669", fontWeight: "bold"}}></i></span>
      </div>
    )
  }

  /**@ExpanderColumns */
  const expanderFields = [
      {
        header: '',
        field: 'sno',
        bodyStyle: { textAlign: "center"}
      }, {
        header: 'Order ID',
        field: 'orderNumber',
        sortable: true,
        filter: true,
        bodyStyle: { textAlign: "left"}

      }, {
        header: 'Seller',
        field: 'seller',
        sortable: true,
        filter: true,
        bodyStyle: { textAlign: "left"}

        // bodyStyle: { width: '400px', wordBreak: 'break-all', whiteSpace: 'pre-line', maxHeight: '100px', overflowY: 'auto', }
      }, {
        header: 'Price',
        field: 'price',
        sortable: true,
        filter: false,
        bodyStyle: { textAlign: "right"}

      }, {
        header: 'Ordered Date',
        field: 'orderedDate',
        sortable: true,
        filter: false,
        body: (data, options) => {
          return <span>{formatDate.formatDate(data?.orderedDate, "MM-DD-YYYY")}</span>
        },
        bodyStyle: { textAlign: "center"}
      }, 
  ];

  /**@TableFields */
  const cartFields = [
      {
          type: 'expander',
          textAlign: "center",
          width: 20,
          field: "expander",
          label: "expander",
          // header: "Procedure Steps",
          expandField: 'orders',
          tableFields: expanderFields,
          headerStyle: {backgroundColor: "##a3a3a3" },
          style: { backgroundColor: "#e7e5e4" },
          show: true,
          mobile: true,
          "textAlign": "center",
          displayInSettings: true,
          rowClassName: (data, options) => {
            return data.sno % 2 === 0 ? "row-even" : "row-odd";
          }
      },
      {
        name: "item",
        type: "item",
        customColumn: productColumn,
        placeholder: "Item",
        label: "Item",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Item",
        derivedValue: "item=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "item",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "itemName",
        fieldType: "customColumn",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        type: "price",
        placeholder: "Price",
        label: "Price",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Price",
        derivedValue: "price=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "center",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showFooterLabel: false,
        footerLabel: "Quantity"
      },
      {
      name: "quantity",
      type: "quantity",
      placeholder: "Qty",
      customColumn: quantityColumn,
      fieldType: "customColumn",
      label: "Qty",
      width: "120px",
      addFormOrder: 5,
      editFormOrder: 5,
      header: "Qty",
      derivedValue: "quantity=undefined",
      capitalizeTableText: false,
      sortable: true,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "quantity",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "true",
      controllerId: null,
      textAlign: "center",
      show: true,
      field: "quantity",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,

      },
      {
        name: "totalPrice",
        type: "totalPrice",
        customColumn: totalColumn,
        fieldType: "customColumn",
        placeholder: "Total",
        label: "Total",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Total",
        derivedValue: "totalPrice=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "totalPrice",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "center",
        show: true,
        field: "totalPrice",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showFooterLabel: false,
        footerLabel: "Total"
      },
  ]


  return (
    <div>
      <ConfirmPopup />
      <div className="header" style={{backgroundColor: "#3C6177", color: "white", fontFamily: `"Inter", sans-serif` }}>
        <div className="d-flex justify-content-between align-items-center mx-5 py-3">
          <span className="d-flex align-items-center gap-3">
          <OrderListIcon /> 
          <span style={{fontSize: "1.2rem", fontWeight: "600", textTransform: "uppercase", cursor: "pointer"}}>Order List</span>
          </span>
          <span className="pointer" onClick={closeFormModal}>
            <i className="pi pi-times fw-bold"></i>
          </span>
        </div>
      </div>

      {
        products && products?.length > 0 ?
        <DataTable
          onRef={(ref) => (cartTableRef.current = ref)}
          getTableFields={() => cartFields}
          formFields={() =>  cartFields}
          type="PurchaseHistories"
          routeTo={apiCalls.getCart}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={"carts"}
          apiUrl={apiCalls.getCart}
          footerClassName="footer__bg_color"
          disableRefresh={true}
          isDisablePaginator={true}
          disableClearFilter={true}
          disableSaveFilter={true}
          disableActionDropdown={true}
          displayActionsInTable={"actions"}
          className="dashboard__table"
          rowClassName="inter__fontfamily px-5 py-5"
          columnHeaderClassName="dashboard__column__header"
        />:
        <div className="flex flex-column justify-content-center" style={{height: "60vh"}}>
          <div className="flex align-items-center justify-content-center">
            <img src={configImages.cartEmpty} style={{height: "200px", width:"200px"}}/>
          </div>
          <span className="text-center fw-bold" style={{color: "#475569"}}>Your Orderlist is empty </span>
        </div>
          
      }
     
    </div>
  )
}

/**
 * @description circle Component using styled components
 */
export const CartCircle = styled.div`
width: 50px; /* Adjust size as needed */
height: 50px; /* Adjust size as needed */
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%; /* Makes it a perfect circle */
background-color: #2563eb;
color: white; /* Ensures icon is visible */
font-size: 20px; /* Adjust icon size if needed */
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds subtle shadow */
`;


export default Cart