/**@Packages */
import React, {useState, useEffect, useContext,  useRef, lazy, Suspense } from "react";
import { useForm, Controller } from "react-hook-form";
import { Card, CardBody, Button } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { faClock, faExclamationCircle, faBicycle, faHeartbeat, faHospitalUser, faStethoscope, faCalendarAlt, faCalendarPlus, faRunning, faFileInvoiceDollar, faDownload, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, Tag } from 'antd';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';

/**@CustomImports */
import DataTable from "../../CommonDataTable/DataTable";
import fetchMethodRequest from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import config from "../../../../config/config";
import configImages from "../../../../config/configImages";
import Loader from "../../../App/Loader";

/**@Context */
import { GlobalContext } from "../../../App/App";
import { BadgeColors } from "../../../../common/Badge/CommonBadge";

const Reports = () => {
  const { control, handleSubmit, watch, reset, getValues, setValue, formState: { errors, isValid }, unregister } = useForm();
  const [providers, setProviders] = useState([]);
  const [tableFields, setTableFields] = useState([]);
  const [showFooter, setShowFooter] = useState(false);
  const [results, setResults ] = useState([]);
  const [loading, setLoading] = useState(false);
  const calRef = useRef(null);

  const context = useContext(GlobalContext);

  useEffect(() => {
    dropdownFocusHandler([])
  }, [context])

  /**@PRIMEREACT TEMPLATES */
  const reportTypeSelectedTemplate = (option, props) => {
    if(option) {
      return (
        <div className="d-flex justify-content-start m-auto gap-3">
          <div>
            <FontAwesomeIcon icon={option?.iconName} color="#dc2626" fontSize={12}/>
          </div>
          <div>{option?.[props.optionLabel]}</div>
        </div>
      )
    }
    else{
      return <span>{props.placeholder}</span>;
    }
  }

  const treatmentPlanSelectedTemplate = (option, props) => {
    if(option){
      return(
        <div className="d-flex align-items-center gap-3">
          <div>
            <span style={{color:"#dc2626", fontWeight:"bolder"}}>{option?.code}</span>
            {option?.code && " - "}
            <span style={{fontWeight:"500"}}>{option?.name}</span>
          </div>
        </div>
      )
    }
    else return <span>{props.placeholder}</span>
  }

  const paymentTypesSelectedTemplate = (option, props) => {
    if(option){
      return (
        <div className="flex">
          {/* <LazyLoadImage name={option.imageName}/> */}
        <img src={option?.image} alt={option.name} className="item-image" style={{width: "24px",height: "24px", margin: 0,objectFit:"contain"}} />
        <span style={{marginLeft:"14px"}}>{option.label}</span>
    </div>
      );
    }
    else{
      return <span>{props.placeholder}</span>;
    }
  }
  
  /**@DropdownHandler */ // Whenever dropdown is in focus then fetch api to get options
  const dropdownFocusHandler = async (state) => {
    if(state.length == 0) {
      await renderOptionsFromAPI("users");
    }
  }


  
    /**@TableFields */
  const patientFields = [
    {
      textAlign: "center",
      width: 47,
      field: "Sno",
      label: "SNo",
      header: "SNo",
      filter: false,
      sortable: false,
      placeholder: "Search",
      show: true,
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
    },
    {
      name: "name",
      type: "text",
      placeholder: "Name",
      label: "Name",
      width: "130px",
      addFormOrder: 6,
      editFormOrder: 6,
      header: "Name",
      derivedValue: "name=undefined",
      capitalizeTableText: true,
      sortable: false,
      actions: [],
      actionsNumber: [],
      id: "name",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      controllerName: null,
      textAlign: "Center",
      globalSearchField: "true",
      show: true,
      field: "name",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
    },
    {
      header: "Clinic Name",
      derivedValue: "clinicName=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "clinicName",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "abbrevation",
      fieldType: "relateAutoComplete",
      textAlign: "Center",
      show: true,
      field: "clinicId",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
    },
    {
      name: "dob",
      // name: "dob",
      type: "date",
      placeholder: "Date Of Birth",
      label: "Date Of Birth",
      width: "130px",
      addFormOrder: 7,
      editFormOrder: 7,
      header: "Date Of Birth",
      derivedValue: "dob=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "dob",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "false",
      controllerId: null,
      dateFormat: config.dateDBFormat,
      fieldType: "Date",
      textAlign: "Center",
      ageCaluclationField:'age',
      show: true,
      // field: "dateOfBirth",
      field: "dob",
      isDob : true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
    },
    {
      name: "gender",
      type: "text",
      placeholder: "Gender",
      label: "Gender",
      width: "130px",
      addFormOrder: 6,
      editFormOrder: 6,
      header: "Gender",
      derivedValue: "gender=undefined",
      capitalizeTableText: true,
      sortable: false,
      actions: [],
      actionsNumber: [],
      id: "gender",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      controllerName: null,
      textAlign: "Center",
      globalSearchField: "true",
      show: true,
      field: "gender",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
      customStyle:"genderBadge"
    },
    {
      name: "phone",
      type: "text",
      placeholder: "Phone",
      label: "Phone",
      width: "130px",
      addFormOrder: 6,
      editFormOrder: 6,
      header: "Phone",
      derivedValue: "phone=undefined",
      capitalizeTableText: true,
      sortable: false,
      actions: [],
      actionsNumber: [],
      id: "phone",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      controllerName: null,
      textAlign: "Center",
      globalSearchField: "true",
      show: true,
      field: "phoneNumber",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
    },
    {
      name: "email",
      type: "text",
      placeholder: "Email",
      label: "Email",
      width: "130px",
      addFormOrder: 6,
      editFormOrder: 6,
      header: "Email",
      derivedValue: "email=undefined",
      capitalizeTableText: true,
      sortable: false,
      actions: [],
      actionsNumber: [],
      id: "email",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      controllerName: null,
      textAlign: "Center",
      globalSearchField: "true",
      show: true,
      field: "email",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
    },

  ]

  const appointmentFields = [
    {
      textAlign: "center",
      width: 47,
      field: "Sno",
      label: "SNo",
      header: "SNo",
      filter: false,
      sortable: false,
      placeholder: "Search",
      show: true,
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
    },
    {
      header: "Patient Name",
      derivedValue: "patientName=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "patientName",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "name",
      fieldType: "relateAutoComplete",
      textAlign: "Center",
      show: true,
      field: "patientId",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
    },
    {
      header: "Provider Name",
      derivedValue: "providerName=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "providerName",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "name",
      fieldType: "relateAutoComplete",
      textAlign: "Center",
      show: true,
      field: "provider",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
    },
    {
      header: "Handler",
      derivedValue: "handler=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "handler",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "name",
      fieldType: "relateAutoComplete",
      textAlign: "Center",
      show: true,
      field: "handler",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
    },
    {
      name: "fromTime",
      // name: "fromTime",
      type: "date",
      placeholder: "Date",
      label: "Date",
      width: "130px",
      addFormOrder: 7,
      editFormOrder: 7,
      header: "Date",
      derivedValue: "fromTime=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "date",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "false",
      controllerId: null,
      // dateFormat: config.twelveHoursTime,
      fieldType: "Date",
      textAlign: "Center",
      ageCaluclationField:'age',
      show: true,
      // field: "dateOfBirth",
      field: "fromTime",
      isDob : true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
      customStyle: "Date"
    },
    {
      name: "fromTime",
      // name: "fromTime",
      type: "date",
      placeholder: "From Time",
      label: "From Time",
      width: "130px",
      addFormOrder: 7,
      editFormOrder: 7,
      header: "From Time",
      derivedValue: "fromTime=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "fromTime",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "false",
      controllerId: null,
      dateFormat: config.twelveHoursTime,
      fieldType: "Date",
      textAlign: "Center",
      ageCaluclationField:'age',
      show: true,
      // field: "dateOfBirth",
      field: "fromTime",
      isDob : true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
      customStyle: "Date"
    },
    {
      name: "toTime",
      type: "Date",
      placeholder: "To Time",
      label: "To Time",
      width: "130px",
      addFormOrder: 7,
      editFormOrder: 7,
      header: "To Time",
      derivedValue: "toTime=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "toTime",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "false",
      controllerId: null,
      dateFormat: config.twelveHoursTime,
      fieldType: "Date",
      textAlign: "Center",
      ageCaluclationField:'age',
      show: true,
      // field: "dateOfBirth",
      field: "toTime",
      isDob : true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
      customStyle: "Date"
    },
  ]

  const procedureFields = [
    {
      textAlign: "center",
      width: 47,
      field: "Sno",
      label: "SNo",
      header: "SNo",
      filter: false,
      sortable: false,
      placeholder: "Search",
      show: true,
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
    },
    {
      header: "Procedure",
      derivedValue: "procedure=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "procedure",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "code",
      fieldType: "relateAutoComplete",
      textAlign: "Center",
      show: true,
      field: "teethProcedureSteps",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      customStyle: "teethProcedure",
    },
    {
      header: "Teeth Number",
      derivedValue: "teethNumber=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "teethNumber",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "teethNumber",
      fieldType: "relateAutoComplete",
      textAlign: "Center",
      show: true,
      field: "teethProcedureSteps",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      customStyle: "teethProcedure",

    },
    {
      header: "Patient Name",
      derivedValue: "patientName=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "patientName",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "name",
      fieldType: "relateAutoComplete",
      textAlign: "left",
      show: true,
      field: "patientId",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      customStyle: "teethProcedure",

    },
    {
      header: "Description",
      derivedValue: "description=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "description",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "description",
      fieldType: "relateAutoComplete",
      textAlign: "left",
      show: true,
      field: "teethProcedureSteps",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      customStyle: "teethProcedure",

    },
    {
      header: "Completed Date",
      derivedValue: "completedDate=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "completedDate",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "completedDate",
      fieldType: "relateAutoComplete",
      textAlign: "Center",
      show: true,
      field: "teethProcedureSteps",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      customStyle: "teethProcedureDate",
      footerLabel: "Total",
      showFooterLabel: true
    },
    {
      header: "Amount",
      derivedValue: "amount=undefined",
      capitalizeTableText: false,
      sortable: false,
      width: "120px",
      actionsNumber: [],
      id: "amount",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      displayOptionsInActions: false,
      globalSearchField: "false",
      searchField: "displayFee",
      fieldType: "relateAutoComplete",
      textAlign: "Center",
      show: true,
      field: "teethProcedureSteps",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      customStyle: "teethProcedure",
      showFooter: true,
      showCurrency: true,
      predicate: ((acc, cur) => acc + cur.teethProcedureSteps.fee)

    },
  ]

  const paymentFields = [
    {
      name: "patientName",
      type: "text",
      placeholder: "patientName",
      label: "patientName",
      header: "Patient Name",
      width: 110,
      parentField: "paymentInfo",
      id: "patientName",
      displayinlist: "true",
      fieldType: "RACSubField",
      textAlign: "left",
      subFieldName: "patientName",
      show: true,
      field: "patientTreatment",
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
    },
    {
      name: "fromTime",
      // name: "fromTime",
      type: "date",
      placeholder: "Date",
      label: "Date",
      width: "130px",
      addFormOrder: 7,
      editFormOrder: 7,
      header: "Date",
      derivedValue: "fromTime=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "date",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "false",
      controllerId: null,
      // dateFormat: config.twelveHoursTime,
      fieldType: "Date",
      textAlign: "Center",
      ageCaluclationField:'age',
      show: true,
      // field: "dateOfBirth",
      field: "fromTime",
      isDob : true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
      customStyle: "Date",
      func: (data) => data["paymentInfo"]["entryDate"]
    },
    {
      name: "paymentType",
      type: "text",
      placeholder: "paymentType",
      label: "paymentType",
      header: "Payment Type",
      width: 110,
      parentField: "patientTreatment",
      id: "paymentType",
      displayinlist: "true",
      // fieldType: "RACSubField",
      textAlign: "Center",
      subFieldName: "paymentType",
      show: true,
      field: "paymentType",
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
      showFooterLabel: true,
      footerLabel: "Total",
      customStyle: "commonBadge",
      func: item => item.patientTreatment.paymentType,
      options: [
        { name: "Cash", value: "Cash", color: BadgeColors.ORANGE},
        { name: "Cheque", value: "Cheque", color: BadgeColors.ROSE},
        { name: "Paypal", value: "Paypal", color: BadgeColors.CYAN},


      ]
    },
    {
      name: "paymentAmount",
      type: "text",
      placeholder: "paymentAmount",
      label: "paymentAmount",
      header: "Amount",
      width: 110,
      parentField: "patientTreatment",
      id: "paymentAmount",
      displayinlist: "true",
      fieldType: "RACSubField",
      textAlign: "Center",
      subFieldName: "paymentAmount",
      show: true,
      field: "paymentAmount",
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
      showFooter: true,
      showCurrency: true,
      predicate: ((acc, cur) => acc + parseFloat(cur.patientTreatment.paymentAmount))
    },

  ]
  /**@StatusIndicators */
  const statusIndicators = [
    { indicatorName: "Pending", bgColor:"#fae8ff"},
    { indicatorName: "Completed", bgColor:"#cffafe"}
  ]

  const filterFields = [
  {
    name: "reportType",
    label: "Report Type",
    type: "dropdown",
    fieldType:"dropdown",
    optionLabel: "label",
    placeholder: "Report Type",
    required: true,
    selectedTemplate: reportTypeSelectedTemplate,

    show: true,
    options: [
      { 
        name: "Patients", 
        label: "Patients",
        iconName: faHospitalUser,
        tableFields: patientFields,
        dependentFieldKeys: ["type"]
      },
      { 
        name: "Provider",
        label: "Provider",
        iconName: faStethoscope,
        tableFields: appointmentFields,
        dependentFieldKeys: ["provider", "filterType"]
      },
      { 
        name: "Appointments",
        label: "Appointments",
        iconName: faCalendarAlt,
        tableFields: appointmentFields,
        dependentFieldKeys: ["provider"]
      },
      {
        name: "Payments",
        label:"Payments",
        iconName: faFileInvoiceDollar,
        tableFields: patientFields,
        dependentFieldKeys: ["paymentTypes"]

      }
    ],

  },
  {
    name: "type",
    label: "Type",
    type: "dropdown",
    fieldType: "dropdown",
    optionLabel: "label",
    placeholder: "Type",
    selectedTemplate: reportTypeSelectedTemplate,
    show: false,
    options: [
      { name: "Scheduled", label: "Scheduled", iconName: faClock, },
      { name: "Un Scheduled", label: "Un Scheduled", iconName: faExclamationCircle, },
      { name: "Revisited", label: "Revisited", iconName: faBicycle, },
      { name: "New Patients", label: "New Patients", iconName: faHeartbeat },
    ],
  },
  {
    name: "provider",
    label: "Providers",
    type: "dropdown",
    optionLabel: "name",
    placeholder:"Provider",
    dropdownFocusHandler: dropdownFocusHandler,
    show: false,
    options: providers
  },
  {
    name: "filterType",
    label: "Filter Type",
    type: "dropdown",
    fieldType: "dropdown",
    optionLabel: "label",
    placeholder: "Filter Type",
    selectedTemplate: reportTypeSelectedTemplate,
    // dropdownFocusHandler: dropdownFocusHandler,
    show: false,
    options: [
      { name: "Schedules", label: "Appointments", iconName: faCalendarPlus },
      { name: "Procedures",
        label: "Procedures",
        iconName: faRunning,
        dependentFieldKeys: ["procedureType", "status"]
      },

    ]
  },
  {
    name: "procedureType",
    label: "Procedure Type",
    type: "dropdown",
    fieldType: "dropdown",
    optionLabel: "label",
    placeholder: "Procedure Type",
    selectedTemplate: treatmentPlanSelectedTemplate,
    // dropdownFocusHandler: dropdownFocusHandler,
    show: false,
    options: [
      {name: "Extraction", code: "D7110"},
      {name: "Bridge", code: config.bridgeProcedureCode},
      {name: "Filling", code:"(D-2335, D-2390, D-2391, D-2392)"},
      {name: "Root Canal", code: "D3920"}
    ]
  },
  {
    name: "status",
    label: "Status",
    type: "dropdown",
    fieldType: "dropdown",
    optionLabel: "label",
    placeholder: "Status",
    options: [
      {name: "Pending", label: "Pending"},
      {name: "Completed", label: "Completed"}
    ]
  },
  {
    name: "paymentTypes",
    label: "Payment Types",
    type: "dropdown",
    fieldType: "dropdown",
    optionLabel: "label",
    placeholder: "Payment Types",
    selectedTemplate: paymentTypesSelectedTemplate,
    show: false,
    options: [
      { name: "Cash", label: "Cash", image:configImages.cashImage, imageName: "cashImage"},
      { name: "Cheque", label: "Cheque", image:configImages.chequeImage, imageName:"chequeImage"},
      { name: "Paypal", label: "Paypal", image:configImages.paypalImage, imageName: "paypalImage"},
      { name: "Cardknox", label: "Cardknox", image: configImages.cardknoxImage, imageName: "cardknoxImage"},
      { name: "Credit Card", label: "Credit Card", image:configImages.creditCardImage, imageName:"creditCardImage"},
      { name: "Zelle", label: "Zelle", image:configImages.ZelleImage, imageName: "ZelleImage"},
      { name: "Venmo", label: "Venmo", image:configImages.VenmoImage, imageName: "VenmoImage"},
      { name: "Cashapp", label: "Cashapp", image:configImages.CashappImage, imageName:"CashappImage"},
      { name: "EFT", label: "EFT", image:configImages.EFTImage, imageName:"EFTImage"},
    ]
  },
  {
    name:"dateRange",
    label:"Date Range",
    type: "calendar",
    fieldType:"calendar",
    optionLabel: "range",
    placeholder: "Date Range",
    show: true,
    required: true
  }
  ]; 



  /**
   * This object is used to get the custom component based on the type of field
   */
  const getComponent = {
    dropdown: CustomDropdown,
    calendar: CustomRangeCal
  }


  /**@Render the @Component based on the type of field */
  const renderFields = (fields, control) => {
    control.setShowFooter = setShowFooter;
    return (
        <>
          {fields.map((fieldObj) => {
            const Component = getComponent[fieldObj.type];
            if(fieldObj.show && Component) {
              return (
                <div className={fieldObj.type == "calendar" ? "order-last ms-auto": ""}>
                  <Component
                    key={fieldObj.name}
                    name={fieldObj.name}
                    label={fieldObj.label}
                    optionLabel={fieldObj.optionLabel}
                    options={fieldObj.options}
                    control={control}
                    placeholder={fieldObj.placeholder}
                    dropdownFocusHandler={fieldObj?.dropdownFocusHandler}
                    selectedTemplate={fieldObj.selectedTemplate}
                    required={fieldObj?.required}
                    reset={reset}
                    setValue={setValue}
                    width={fieldObj?.width}
                    generate={handleSubmit(onSubmit)}
                    clearFilter={clearFilter}
                    calRef={calRef}
                  />
                </div>
              )
            }
          })}
        </>
    );
  };

  /**@This will get the option from api for dropdowns */
  const renderOptionsFromAPI = async apiUrl => {
    let filter = `{"sortfield":"created","direction":"desc","criteria":[{"key":"clinicsId","value":["${context?.clinicData._id}"],"type":"in"},{"key":"role","value":"Provider","type":"regexOr"}]}`
    let response = await fetchMethodRequest("GET", apiUrl + "?filter=" + filter);
    if(response?.errorCode) showToasterMessage(response?.errorMessage,'error');
    setProviders(response.users);
    let filterChairsOptions = response.chairs.filter(chair => chair.hospitalId._id === context?.clinicData.hospitalId._id);
  }

  

  /**@Render the @DependentFields using Watch */
  const renderDependentFields = (type) => {
    if (type?.dependentFieldKeys?.length > 0) {
      let alldependentFieldKeys = [];
      for(let key of type?.dependentFieldKeys){
        let findDependentField = filterFields.find(field => field.name == key);
        findDependentField.show = true;
        if(findDependentField) alldependentFieldKeys.push(findDependentField)
    }
    return renderFields(alldependentFieldKeys, control);
    }
    return null;
  };

  /**This will create a new anchor link and open it new window (automatically) */
  const createAnchorLinkAndDownload = (pdfLink) => {
    const link = document.createElement('a');
    link.href = pdfLink;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**@SubmitHandler */
  const onSubmit = (data, type) => {
    let modifiedBody = Object.assign({clinic: context.clinicData, patient: context.patientData}, data);
    for(let key in data){
      let findField = filterFields.find(field => field.name == key);
      if(findField?.fieldType == "dropdown" && data[key] && data[key]["name"]){
        modifiedBody[key] = data[key]["name"]
      }
    }

    /**@GenerateReports */
    if(type === "generateReports") {
      generateReports(modifiedBody);
      return;
    }

    /**@TriggerSendEmailFunction */
    if(type === "sendEmail"){
      sendEmail(modifiedBody);
      return;
    }
    setResults([]);
    setLoading(true);

    if(data?.reportType?.tableFields){
      if(data && data.filterType?.name === "Procedures") setTableFields(procedureFields), setShowFooter(true);
      else if(data && data.reportType?.name === "Payments") setTableFields(paymentFields), setShowFooter(true);
      else setTableFields(data.reportType.tableFields);
    } 

    /**@After recieve the response create anchor tag download the pdf (link is in response) */
    fetchMethodRequest("POST", apiCalls.reports, modifiedBody).then(res => {
      if(res && res?.respCode) {
        if(data?.filterType?.name === "Procedures"){
          let response = []; 
          res.reports.forEach(tp => {
            let result = tp.teethProcedureSteps.map( (pro,i) => ({...tp, teethProcedureSteps: pro}));
            response = [...response, ...result];
          })
          setResults(response.map((resp,i) => ({...resp, Sno: i+1})));
        }
        else{
          setResults(res.reports.map((resp,i) => ({...resp, Sno: i+1})));
        }
      }
      setLoading(false);
      showToasterMessage(res?.errorMessage,'error');
    })
    
  };
  

  /**@ClearAllFilters */
  const clearFilter = (e) => {
    reset();
    setResults([]);
  }
  /**@HandleGenerateReportsFunction */
  const generateReports = (body) => {
    fetchMethodRequest("POST", apiCalls.generateReports, body).then(res => {
      if(res && res?.respCode){
        createAnchorLinkAndDownload(config.previousImageUrl + `reports/${res.fileName}`);
      }
      else{
        showToasterMessage(res?.errorMessage, 'error');
      }
    })
  }


  /**@SendEmail */
  const sendEmail = body => {
    fetchMethodRequest("POST", apiCalls.sendEmailReports, body).then(res => {
      if(res && res?.respCode){
        showToasterMessage(res?.respMessage, "success");
      }
      else{
        showToasterMessage(res?.errorMessage, "error");
      }
    })
  }


  /**@Watch the reportType and filterType to render the dynamic(dependent) form fields */

  const selectedType = watch("reportType");
  const filterType = watch("filterType");

  /**Render Main @Components */
  return (
    <>
      <Loader loader={loading} />
      <Card className="cardForListMargin">
        <CardBody className="tableCardBody h-100">
          <span style={{fontSize:"1.2rem", fontWeight:"bold", padding:"10px"}}>Reports</span>
          <div className="border m-2 p-2 rounded">
            <form>
              <div className="d-flex gap-3 p-4">
                  {renderFields(filterFields, control)}
                  {renderDependentFields(selectedType)}
                  {renderDependentFields(filterType)}
                </div>
                <div className="flex align-items-center gap-3 mx-4 mb-3">
                  {
                    results.length > 0 && 
                      <>
                        <Tag icon={<FontAwesomeIcon icon={faDownload} className="me-2" stroke="0.5"/>} color={"magenta"} className="py-2 px-2 fw-bold" onClick={handleSubmit((data) => onSubmit(data,"generateReports"))} style={{cursor:"pointer"}}>Download Report</Tag>
                        <Tag icon={<FontAwesomeIcon icon={faEnvelope} className="me-2"/>} color={"cyan"} className="py-2 px-2 fw-bold" onClick={handleSubmit((data) => onSubmit(data,"sendEmail"))} style={{cursor:"pointer"}}>Send Email</Tag>
                      </>
                  }
                    { filterType?.name == "Procedures" &&  <StatusIndicators status={statusIndicators} show={filterType?.name === "Procedures" && results.length > 0}/> }
                    <div className="flex ms-auto gap-4">
                      {
                        isValid && 
                        <>
                          <Button color="danger" onClick={clearFilter} className="m-auto">Clear</Button>
                          <Button color="primary" onClick={handleSubmit(onSubmit)} className="m-auto">Generate</Button>
                        </> 
                      }
                    </div>
                </div>
            </form>
          </div>
          {
            tableFields.length > 0 &&
            <div className="mx-2 px-2">
                <DataTable
                  getTableFields={() => tableFields}
                  formFields={()=> filterFields}
                  showFooter={showFooter}
                  footerClassName="footer__bg_color"
                  results={results}
                  tableFields={tableFields}
                  type={"Reports"}
                  displayViewOfForm="screen"
                  disableRefresh={true}
                  showFilterOptions={true}
                  disableHeader={true}
                  isDisablePaginator={true}
                  disableClearFilter={true}
                  disableSaveFilter={true}
                  disableActionDropdown={true}
                  resultsFromParent
                  className="dashboard__table"
                  tableScrollHeight={"600px"}
                  rowClassName={(filterType?.label != "Procedures") ? "inter__fontfamily px-5 py-5" : ""}
                  columnHeaderClassName="dashboard__column__header"
                />
            </div>
          }
        </CardBody>
      </Card>
    </>
  );
};

/**@CustomDropdown Component */
const CustomDropdown = ({ name, label, placeholder,optionLabel, options, control, dropdownFocusHandler, selectedTemplate, required, reset, setValue, width, generate, clearFilter, showClearIcon = true, clearIcon = clearIconFun }) => {
  let onFocusHandler;
  let itemTemplate;
  if(dropdownFocusHandler) onFocusHandler = () => dropdownFocusHandler(options);
  if(selectedTemplate) itemTemplate = (option) => selectedTemplate(option, { optionLabel })
  
  
  /**@ClearDependentValues */
  const clearDependentValues = (event, field, generateData) => {
    if(field?.name == "reportType"){
      setValue("reportType", null),
      setValue("provider", null),
      setValue("procedureType", null);
      setValue("filterType", null)
      setValue("status", null);
    }


    /**@Reset tableFooter state */
    field.onChange(event.value);
    control.setShowFooter(false);
    generateData();
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={required && {required: `Select ${label}`}}
      render={({ field, fieldState: { error } }) => {
        field.clearDependentValues = clearDependentValues;
        return <div className="d-flex flex-column">
          <div className="flex align-items-center">
            <label htmlFor={name} className="text-capitalize ms-2" style={{fontWeight:"bold"}}>{label}</label>
            { required && <MarkRequired />}
          </div>
          <Dropdown
            value={field.value}
            {...field}
            options={options}
            clearIcon={(e) => clearIcon(e, field, generate, clearFilter)}
            showClear={showClearIcon}
            placeholder={placeholder}
            optionLabel={optionLabel}
            onFocus={ onFocusHandler && onFocusHandler }
            onChange={(e) => clearDependentValues(e, field, generate)}
            valueTemplate={selectedTemplate && selectedTemplate}
            style={{height: "50px", width: width ? width : "200px"}}
            itemTemplate={itemTemplate && itemTemplate}
          />
          <small style={{color:"red", marginLeft:"15px"}}>{error && error?.message}</small>
        </div>
      }}
    />
  );
};

/**@CustomDateRangeComponent from antd */
const CustomRangeCal = ({ name, label, placeholder,control, calRef, required, generate }) => {

  const rangePresets = [
    {
      label: 'Tomorrow',
      value: [dayjs().add(+1, 'd'), dayjs()],
    },
    {
      label: 'Next 7 Days',
      value: [dayjs().add(+7, 'd'), dayjs()],
    },
    {
      label: 'Next 14 Days',
      value: [dayjs().add(+14, 'd'), dayjs()],
    },
    {
      label: 'Next 30 Days',
      value: [dayjs().add(+30, 'd'), dayjs()],
    },
    {
      label: 'Next 90 Days',
      value: [dayjs().add(+90, 'd'), dayjs()],
    },
    {
      label: 'Next 180 Days',
      value: [dayjs().add(+180, 'd'), dayjs()],
    },
  ];

  /* GetValue Function */
  const getValue = (field) => {
    if(!field?.value?.length) return null;
    return [dayjs(field.value[0]), dayjs(field.value[1])];
  };


  return (
    <div className="order-10">
        <Controller
          name={name}
          control={control}
          rules={required && {required: `Select ${label} to generate the report`}}
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <div className="flex align-items-center">
                  <label htmlFor={label} className="text-capitalize ms-2" style={{ fontWeight: "bold" }}>{label}</label>
                  {required && <MarkRequired />}
                </div>
                <RangePicker
                  ref={calRef}
                  allowClear={false}
                  style={{height: "50px"}}
                  value={getValue(field)}
                  onChange={(dates, dateStrings) => {
                      const formattedDates = dateStrings.map((date, index) =>{
                        if(index > 0) return dayjs(date).endOf('day').format('YYYY/MM/DD HH:mm:ss [GMT]Z');
                        else return dayjs(date).format("YYYY/MM/DD HH:mm:ss [GMT]Z")
                      });
                    field.onChange(formattedDates);
                    generate();
                  }}
                  format="MM-DD-YYYY"
                  placement={"bottomRight"}
                  size={"large"}
                  onCalendarChange={(dates, dateStrings, info) =>
                    console.log("Calendar Changed:", dateStrings)
                  }
                  presets={[
                    {
                      label: (
                        <span aria-label="Current Time to End of Day">Today</span>
                      ),
                      value: () => [dayjs(), dayjs().endOf("day")],
                    },
                    ...rangePresets,
                  ]}
                />
                <small style={{color:"red", marginLeft:"15px", marginTop:"2px", display:"block"}}>{error && error?.message}</small>
              </div>
            );
          }}
        />
    </div>
	);
};

/**@MarkRequired Component to render the {*} for required fields */
const MarkRequired = () => {
  return <div className="ms-1">
    <FontAwesomeIcon
          color='red'
          icon={faAsterisk}
          style={{ width: '7px',marginLeft: "2px" }}
    />
  </div>
}

/**@StatusIndicators */
const StatusIndicators = ({ show = false, status }) => {
  return (
    <>
    {
      show && 
      <div className="d-flex justify-content-end gap-4 me-5 border py-2 px-3 shadow rounded">
        {
          status.map( s => {
            return (
              <div className="flex gap-2">
                <div style={{width:"20px", height:"20px", backgroundColor: s.bgColor, color: s.color ? s.color: "black", borderRadius:"4px"}} className="border"></div>
                <span className="fw-bold">{s.indicatorName}</span>
              </div>
            )
          })
        }
      </div>
    }
    </>
  )
}


/**@PRIMEREACT DropdownUtilsSection */

  /**@ClearIcon Option for dropdown to clear the selected value */
  const clearIconFun = (event, field, generate, clearFilter) =>{
    return (
      <div className="m-auto" onClick={(e) => { e.stopPropagation(), field.onChange(null), field.clearDependentValues(e, field,generate)}}>
        <i className="pi pi-times" style={{ fontSize: '.6rem', fontWeight:"bolder", color:"red"}}></i>
      </div>
    )
  }


export default Reports;
