import React, { useState, useEffect, useContext } from 'react';
import './OrthoForm.scss';
import OrthoFormDropzoneComponent from './OrthoFormDropZone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThLarge, faFileImage, faImage, faPlus, faTrashAlt, faSync } from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from '../../../App/App';
import { Button as ReactStrapButton } from 'reactstrap';
import config from '../../../../config/config';
import moment from 'moment';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../../../config/service';
import DoSelectField from '../../../Form/Fields/DoSelectField';
import { useForm, Controller } from 'react-hook-form';

const OrthoForm = (props) => {
  const [view, setView] = useState('matrix');  // Control the display mode
  const context = useContext(GlobalContext);
  const [dropzones, setDropzones] = useState([{ id: 1 }]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [rotationDegrees, setRotationDegrees] = useState({});
  const [allImagesUploaded, setAllImagesUploaded] = useState(false);
  const [dropdownValue, setDropdownValue] = useState();
  const totalBoxes = Array.from({ length: view === 'matrix' ? 9 : 4 });
  const [clearFlag, setClearFlag] = useState(false);
  let {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm();
  
  useEffect(() => {
    if (context?.patientData === null || (typeof context?.patientData === 'string' && context.patientData.trim() === "")) {
      showToasterMessage("Please select a patient", "warning");
      setUploadedFiles({});
      setRotationDegrees({});
    }
  }, [context]);

  useEffect(() => {
    const requiredCount = view === 'matrix' ? 8 : 1;
    const uploadedCount = Object.keys(uploadedFiles).length;
    setAllImagesUploaded(uploadedCount >= requiredCount);
  }, [uploadedFiles, view]);

  useEffect(() => {
    // Reset clearFlag after setting it to true
    if (clearFlag) {
      setClearFlag(false);
    }
  }, [clearFlag]);

  
  const boxTexts = [
    "Side View",
    "Front View",
    "Smile View",
    "Upper or Maxillary Arch",
    "Practice Name and also Logo\nPt Name DOB\nDate Taken",
    "Lower or Mandibular Arch",
    "Right Side",
    "Front",
    "Left Side"
  ];

  const handleRemoveImage = (fileId) => {
    const updatedFiles = { ...uploadedFiles };
      delete updatedFiles[fileId];
    setUploadedFiles(updatedFiles);
  };

  const handleRotateImage = (fileId) => {
    const currentAngle = rotationDegrees[fileId] || 0;
    const newAngle = (currentAngle + 90) % 360;
    setRotationDegrees({
      ...rotationDegrees,
      [fileId]: newAngle
    });
  };
  const renderContent = (index) => {
    const imageId = view === 'matrix' ? `image${index + 1}` : `image${10 + index}`;
    const image = uploadedFiles[imageId];
    if (index === 4) {
      return (
        <div className="patient-name">
          {context.patientData ? (
            <>
              <span>{context?.patientData?.firstName} {context?.patientData?.lastName}</span>
              <span>DOB: {moment(context.patientData?.dateOfBirth).format(config.dateDBFormat)}</span>
              <span>{context?.patientData?.hospitalId?.hospitalName}</span>
            </>
          ) : " "}
        </div>
      );
    } else {
      return (
        <div className="image-uploads-specific">
          <OrthoFormDropzoneComponent
            key={`${context?.patientData?._id || 'no-patient'}-${imageId}`}
            label={view === 'matrix' ? boxTexts[index] : `Upload Image`}
            url={`uploads/uploadAttachments?type=patients`}
            onFileUpload={(fileId, file) => handleFileUpload(imageId, file)}
            onFileRemove={() => handleRemoveImage(imageId)}
            resetUploads={clearFlag}
          />
        </div>
      );
    }
  };


  const statusOptions = [
    { label: 'Before Treatment', value: 'Before Treatment' },
    { label: 'After Treatment', value: 'After Treatment' },
  ];

  let getDropdown = (i, item) => {
    const DropdownValue = (value, e) => {
      setDropdownValue(value);
    }
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
              item={item}
              screenName={props.screenName}
              onChange={DropdownValue}
            />)}
        />
      </div>
    )
  }

  const handleFileUpload = (imageId, file) => {
    setUploadedFiles(prev => ({
      ...prev,
      [imageId]: file
    }));
  };  

  const clearUploadedFiles = () => {
    setUploadedFiles({});
    setRotationDegrees({});
    setAllImagesUploaded(false);
    setClearFlag(true); // Set this to trigger a reset in child components
  };
  
  const handleCancel = () => {
    clearUploadedFiles(); // Clear images when canceling
  };

  const submitForm = () => {
    let url = `patients/${context?.patientData?._id}`; // Adjust the URL as needed
    let patientId = context?.patientData;
    const attachments = Object.keys(uploadedFiles).map((fileId) => {
      const fileIndex = fileId.replace('image', ''); // Assuming fileId is 'image1', 'image2', etc.

      const isFile = uploadedFiles[fileId] instanceof File;
      const fileName = isFile ? uploadedFiles[fileId].name : uploadedFiles[fileId].fileName;
      const filePath = isFile ? uploadedFiles[fileId].objectURL : undefined; // Adjust as needed for normal objects if they have a path
      return {
        id: `image${fileIndex}`,
        type: "Patient Image",
        filePath: filePath,
        fileName: fileName
      };
    }).sort((a, b) => parseInt(a.id.replace('image', '')) - parseInt(b.id.replace('image', '')));

    const imageUploadType = (view === "matrix") ? "multiUpload" : "singleUpload";
    let body = {
      attachment: [
        {
          imageUploads: attachments
        }
      ],
      treatmentStatus: dropdownValue,
      patientId: patientId,
      imageUploadType:imageUploadType
    };

    fetchMethodRequest('PUT', url, body)
      .then(async (response) => {
        if (response && response.respCode) {
          clearUploadedFiles();
          showToasterMessage(response.respMessage, 'success');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  };
  const handleViewChange = (newView) => {
    if (newView !== view) {
      setView(newView);
      setDropzones(newView === 'single' ? [{ id: 10 }, { id: 11 }] : [{ id: 1 }]);
      setUploadedFiles({});
      setRotationDegrees({});
    }
  };  
  
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="icon-controls">
          <FontAwesomeIcon icon={faThLarge} onClick={() => handleViewChange('matrix')}
            className={view === 'matrix' ? '' : 'icon-selected'}
          />
          <FontAwesomeIcon icon={faFileImage} onClick={() => handleViewChange('single')}
            className={view === 'single' ? '' : 'icon-selected'}
          />
        </div>
        {context.patientData && (
        <div className="treatment-status-container">
          <label className="treatment-status-label">TREATMENT STATUS<span className="required-asterisk">*</span></label>
          <div>
            {getDropdown(1, { name: 'treatmentStatus', type: 'dropdown', options: statusOptions, show: true, label: 'Treatment Status', placeholder: "Treatment Status", id: "treatmentStatus", width: "130px", required: true, disabled: false, disableLabel: "true" })}
          </div>
        </div>
        )}
        <div className="control-buttons">
          <ReactStrapButton color="primary" className="update-button" onClick={submitForm} disabled={!allImagesUploaded || !dropdownValue}>Submit</ReactStrapButton>
          <ReactStrapButton color="primary" className="cancel-button" onClick={handleCancel}>Clear</ReactStrapButton>
        </div>
      </div>
      <div>
      <div className={context.patientData ?  (view === 'single' ? "image-uploads-single" : "image-uploads-wrapper") : "dynamic-image-class"}>
        <div className="image-grid">
          {totalBoxes.map((_, index) => (
            <div className="image-item" key={index}>
              {renderContent(index)}
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default OrthoForm;
