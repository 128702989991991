import React, { useEffect, useRef, useState } from "react";
import DoAutoCompleteField from "../../../Form/Fields/DoAutoCompleteField";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import fetchMethodRequest from "../../../../config/service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faCheck } from '@fortawesome/free-solid-svg-icons';
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import Pharmacies from "../../Pharmacies/components/Pharmacies";
import RolePermissions from "../../CommonModals/Permissions";


import { Button } from 'primereact/button';

export default function PharmacyInputField(props) {
    let { i, item, Controller, control, getValues, setValue, errors, selectedData, setCustomFieldValidation, loginRole } = props;
    const [formType, setFormType] = useState(window.location.href.includes("create") ? 'add' : 'edit');
    const [isOpenFormModal, setIsOpenFormModal] = useState(false);
    const [stateOptions, setStateOptions] = useState();
    const [rolePermission, setRolePermission] = useState();


    const formModalRef = useRef(null);

    useEffect(() => {
        let screenPermissions = RolePermissions.screenPermissions("Drugs");
        if (screenPermissions) {
            setRolePermission(screenPermissions);
        }

        setStateData();
    }, []);

    const isEditPermission = rolePermission?.type == "Edit" ? true : false;

    const setStateData = async () => {
        let arr = [];
        if (localStorage.StatesOptions) {
            arr = await JSON.parse(localStorage.StatesOptions);
        } else {
            let a = await fetchMethodRequest('GET', apiCalls.Settings).then(res => {
                if (res.settings[0]?.states?.length) {
                    arr = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
                }
            })
        }
        setStateOptions(arr);
    }

    /**
     * 
     * @param {Number} i 
     * @param {Object} item 
     * @param {Function}} Controller 
     * @param {Function} control 
     * @param {Function} getValues 
     * @param {Function} setValue 
     * @param {Object} errors 
     * @returns 
     */
    let getAutoCompleteField = (i, item, Controller, control, getValues, setValue, errors) => {//Render the Multiple select field
        const itemTemplateFunction = (rowData) => {
            if (rowData.fax && typeof rowData.fax == 'number') {
                rowData.fax = 'Fax : ' + rowData.fax;
            }
            if (rowData.phone && typeof rowData.phone == 'number') {
                rowData.phone = 'Phone : ' + rowData.phone;
            }
            let addressArr = [];
            ['address', 'address2', 'city', 'state', 'zipCode', 'fax', 'phone'].map(key => {
                rowData[key] ? addressArr.push(rowData[key]) : null;
            })

            return <span class="text-capitalize">{rowData[item.searchField]}<br />
                <i title={addressArr.join(', ')} style={{
                    maxWidth: '450px',
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
                    textOverflow: 'ellipsis',
                }}><small>{addressArr.join(', ')}</small></i>
            </span>;
        }

        return <div>
            <Controller
                name={item.name}
                control={control}
                render={({ field, fieldState }) => (
                    <DoAutoCompleteField
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        item={{ ...item, itemTemplate: itemTemplateFunction }}
                        filterField={item.filterField}
                        filterValue={item.filterValue}
                        filterType={item.isNotEq}
                        multiple={item.isMultiple}
                        fieldState={fieldState}
                        errors={errors}
                        screen={props.type}
                        searchApi={item.searchApi}
                        itemTemplate={itemTemplateFunction}
                        searchField={item.searchField}
                        filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                        placeholder={item.placeholder}
                        label={item.label}
                        setValue={setValue}
                        getValues={getValues}
                        formType={props.formType}
                    />)}
            />
        </div>
    }

    const getFormFields = () => {
        return [
            {
                name: "storeName",
                type: "text",
                placeholder: "Store Name",
                label: "Store Name",
                derivedValue: "storeName=undefined",
                capitalizeTableText: false,
                id: "storeName",
                required: true,
                show: true,
                mobile: true,
                displayInSettings: true,
                customWidthClass: 'col-sm-6',
                style: {
                    color: "#0e4768",
                    cursor: "pointer",
                    textTransform: "capitalize",
                },
            },
            {
                name: "phone",
                type: "number",
                placeholder: "Phone",
                label: "Phone",
                required: true,
                show: true,
                mobile: true,
                fieldType: 'phone',
                maxLength: config.phoneNumberLength,
                customWidthClass: 'col-sm-6',
                displayInSettings: true,
            },
            {
                name: "fax",
                type: "number",
                placeholder: "Fax",
                label: "Fax",
                id: "fax",
                required: false,
                displayOptionsInActions: false,
                show: true,
                customWidthClass: 'col-sm-6',
                mobile: true,
                displayInSettings: true,
            },
            {
                name: "address",
                type: "text",
                placeholder: "Address",
                label: "Address",
                required: false,
                displayOptionsInActions: false,
                globalSearchField: "true",
                customWidthClass: 'col-sm-6',
                show: true,
                mobile: true,
                displayInSettings: true,

            },
            {
                name: "address2",
                type: "text",
                placeholder: "Address2",
                label: "Address2",
                required: false,
                displayOptionsInActions: false,
                showOrHideFields: [],
                customWidthClass: 'col-sm-6',
                mobile: true,
                displayInSettings: true,
            },
            {
                name: "city",
                type: "text",
                placeholder: "City",
                label: "City",
                id: "city",
                required: false,
                globalSearchField: "true",
                show: true,
                showOrHideFields: [],
                customWidthClass: 'col-sm-6',
                mobile: true,
                displayInSettings: true,
            },
            {
                name: "state",
                type: "dropDown",
                placeholder: "State",
                label: "State",
                id: "state",
                required: false,
                displayOptionsInActions: false,
                globalSearchField: "true",
                controllerId: null,
                show: true,
                options: stateOptions,
                itemTemplate: true,
                filter: true,
                showOrHideFields: [],
                customWidthClass: 'col-sm-3',
                mobile: true,
                displayInSettings: true,
            },
            {
                name: "zipCode",
                type: "number",
                placeholder: "ZIP Code",
                label: "ZIP Code",
                id: "zipCode",
                required: false,
                customWidthClass: 'col-sm-3',
                displayOptionsInActions: false,
                show: true,
                mobile: true,
                displayInSettings: true,
            },
            {
                name: "note",
                type: "textarea",
                placeholder: "Note",
                label: "Note",
                id: "note",
                customWidthClass: 'col-sm-6',
                required: false,
                displayOptionsInActions: false,
                show: true,
                displayInSettings: true,
            },
        ];
    };

    const addNewPharmacy = (e) => {
        e.preventDefault();
        setIsOpenFormModal(true);
        setCustomFieldValidation({ save: false, });
    }

    const closeFormModal = (formType, response) => {
        if (formType == 'save') {
            fetchMethodRequest("GET", `${apiCalls.pharmacies}/${response.pharmaciesId}`).then(res => {
                if (res.details) {
                    setValue('pharmacy', res.details);

                }
            })
        }
        setIsOpenFormModal(false);
        setCustomFieldValidation({ save: true });
    }

    return <div className="drug_datatable">
        <div className="d-flex align-items-center mb-1">
            <label>Pharmacy</label>
            {isEditPermission && <Button rounded className="drugsfield_add_icon" type="button"><FontAwesomeIcon
                icon={faPlus}
                size='lg'
                data-toggle="tool-tip"
                title={"Add"}
                type="button"
                onClick={(event) => {
                    event.preventDefault(); // Prevent default form submission
                    addNewPharmacy(event); // Call the intended function
                }}
            /></Button>}
        </div>
        <div className="row">{getAutoCompleteField(i, item, Controller, control, getValues, setValue, errors)}</div>

        {isOpenFormModal && stateOptions &&
            <FormModal
                onRef={(ref) => formModalRef.current = ref}
                openFormModal={isOpenFormModal}
                allUsersData={[]}
                tableRowData={{}}
                totalRecords={1}
                first={1}
                rows={1}
                closeFormModal={closeFormModal}
                type={'Pharmacies'}
                tablefieldsToShow={[]}
                originalTableFields={[]}
                formType={'add'}
                // formFields={Pharmacies().getFormFields}
                formFields={getFormFields}
                getDataFromServer={() => { }}
                apiUrl={apiCalls.pharmacies}
                role={loginRole}
                filterCriteria={{}}
                menuList={[]}
                routeTo={apiCalls.prescriptions}
                displayViewOfForm={'modal'}
                actionsTypes={'add'}
                entityType={'users'}
            />
        }
    </div>

}