const imagePath = '../assets/';
const configImages = {
  vid: require(`../assets/img/logo/video.png`),
  csv: require(`../assets/img/logo/csv.png`),
  xlsx: require(`../assets/img/logo/xlsx.png`),
  xls: require(`../assets/img/logo/xlsx.png`),
  ppt: require(`../assets/img/logo/ppt.jpg`),
  pdf: require(`../assets/img/logo/pdf.jpg`),
  json: require(`../assets/img/logo/json.jpg`),
  txt: require(`../assets/img/logo/txt.jpg`),
  docx: require(`../assets/img/logo/docx.jpg`),
  doc: require(`../assets/img/logo/docx.jpg`),
  defaultImg: require(`../assets/img/profile/defaultImg.png`),
  defaultUserImage: require(`../assets/img/profile/default_user_image.png`),
  usersSampleXL: require(`../assets/files/scheduleSampleUpload.xlsx`),
  proceduresSampleXL: require(`../assets/files/sample_procedures_bulk_upload_file.xlsx`),
  loginImage: require(`../assets/img/logo/default_project_logo.png`),
  loginSideImage: require(`../assets/img/logo/default_LoginSideImage.webp`),
  // loginLogo: require(`../assets/img/logo/loginLogo.jpg`),
  loaderImage: require(`../assets/loader.gif`),
  prescriptionDowmloadImage: require(`../assets/img/downloadPrescriptionHeader/prescriptionDowmloadImage.jpg`),
  cashImage: require("../assets/img/payment/money-stack.png"),
  chequeImage: require("../assets/img/payment/cheque.png"),
  paypalImage: require("../assets/img/payment/paypal.png"),
  cardknoxImage: require("../assets/img/payment/cardknox.png"),
  creditCardImage: require("../assets/img/payment/credit-card.png"),
  careCreditImage: require("../assets/img/payment/carecredit.png"),
  VenmoImage: require("../assets/img/payment/venmo.png"),
  ZelleImage: require("../assets/img/payment/zelle.png"),
  EFTImage: require("../assets/img/payment/eft.png"),
  CashappImage: require("../assets/img/payment/cashapp.png"),
  InventoryPlaceholder: require("../assets/img/logo/placeholder.png"),
  cartEmpty: require("../assets/img/logo/empty-cart.png")




};
export default configImages;
