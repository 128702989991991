import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import { Badge, Divider } from "antd";
import configImages from "../../../../config/configImages";
import apiCalls from "../../../../config/apiCalls";
import DataTable from "../../CommonDataTable/DataTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import CartPlus from "mdi-react/CartAddIcon"



/**@ProductTitle Style using Styled Components */
const ProductTitle = styled.div`
 display: flex;
 gap: 20px;
 font-size: 1.2rem;
 color: #0e4768;
 padding: 10px 0px;
 margin-left: 10px;
 cursor: pointer;
`


const ViewInventory = (props) => {
  const [purchaseHistories, setPurchaseHistories] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState( { criteria: [ { key: "itemID", value: props?.viewInfo.itemID, type: "eq"}], sortField: "orderedDate", direction: "desc"});
  let dataTableRef = useRef(null);
  const inventoryFields = [
    { header: "Product ID", field: "itemID" },
    { header: "Quantity", field: "totalQuantity" },
    { header: "Total Price", field: "totalPrice" },
  ]

  const purchaseHistoryFields = [
      {
        name: "orderID",
        type: "orderID",
        placeholder: "Order ID",
        label: "Order ID",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Order ID",
        derivedValue: "orderID=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "orderID",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "orderNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "seller",
        type: "seller",
        placeholder: "Seller",
        label: "Seller",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Seller",
        derivedValue: "seller=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "seller",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "seller",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showFooterLabel: false,
        footerLabel: "Quantity"
      },
      {
      name: "quantity",
      type: "quantity",
      placeholder: "Quantity",
      label: "Quantity",
      width: "120px",
      addFormOrder: 5,
      editFormOrder: 5,
      header: "Quantity",
      derivedValue: "quantity=undefined",
      capitalizeTableText: false,
      sortable: true,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "quantity",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "true",
      controllerId: null,
      textAlign: "center",
      show: true,
      field: "quantity",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      tDisplay: true,
      showFooter: true,
      predicate: ((acc, cur) => acc + cur.quantity)

      },
      {
        name: "price",
        type: "price",
        placeholder: "Price",
        label: "Price",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Price",
        derivedValue: "price=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "right",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showFooterLabel: false,
        footerLabel: "Total"
      },
      {
        name: "totalPrice",
        type: "totalPrice",
        placeholder: "Total Price",
        label: "Total Price",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Total Price",
        derivedValue: "totalPrice=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "totalPrice",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "right",
        show: true,
        field: "total",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showFooter: true,
        showCurrency: true,
        predicate: ((acc, cur) => acc + cur.total)
      },
      {
        name: "orderedDate",
        type: "orderedDate",
        placeholder: "Ordered Date",
        label: "Ordered Date",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Ordered Date",
        derivedValue: "orderedDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "orderedDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "center",
        show: true,
        field: "orderedDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        customStyle: "Date",
        dateFormat: "MM-DD-YYYY"
      },
  ]
  
  const closeFormModal = () => {
    props.setIsOpenFormModal(false);
  }

  return (
    <div style={{fontFamily:'"Inter", sans-serif'}} className="mx-5">
      <Link to={"/inventories"} >
        <ProductTitle onClick={closeFormModal} >
          <span style={{cursor: "pointer"}}>
            <FontAwesomeIcon 
              icon={[ "fa", "arrow-left"]}
              size={24}
            />
          </span>
          <span className="fw-bold">Product Name: </span>
          <span>{props?.viewInfo.productName}</span>
        </ProductTitle>
        <Divider style={{margin: "2px"}}/>
      </Link>
      <div className="">
        <Row>
          <Col md={3}>
          <div className="d-flex align-items-center h-100">
            <div className="d-flex flex-column align-items-center shadow-sm card__container px-4 py-2 border">
              <div onClick={() => props?.addToCart({ item: props.viewInfo })}>
                <Badge count={<CartPlus className="mt-4" color={"#0e4768"} size={24} />}>
                  <ImageComponent imageUrl={props?.viewInfo.imageUrl}/>
                </Badge>
              </div>
              <Divider/>
              <span className="productName">{props.viewInfo?.itemName}</span>
              <div className="keyValueContainer">
                {inventoryFields.map(field => {
                  return(
                    <>
                      <div className="key">{field.header}</div>
                      <div className="value">{props?.viewInfo[field.field]}</div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
          </Col>
          <Col md={9}>
            <DataTable
              {...props}
              onRef={(ref) => (dataTableRef.current = ref)}
              getTableFields={() => purchaseHistoryFields}
              formFields={() =>  purchaseHistoryFields}
              type="PurchaseHistories"
              routeTo={apiCalls.purchaseHistories}
              displayViewOfForm="screen"
              sideFormLeftOrRight=""
              apiResponseKey={apiCalls.purchaseHistories?.toLowerCase()}
              apiUrl={apiCalls.purchaseHistories}
              footerClassName="footer__bg_color"
              showFooter={true}
              disableRefresh={true}
              isDisablePaginator={true}
              disableClearFilter={true}
              disableSaveFilter={true}
              disableActionDropdown={true}
              displayActionsInTable={"actions"}
              tableScrollHeight={"60vh"}
              className="dashboard__table"
              rowClassName="inter__fontfamily px-5 py-5"
              columnHeaderClassName="dashboard__column__header"
              defaultFilterCriteria={filterCriteria}
              filterExtension={`type=exportToCsv`}
              header={<div className="flex"><TableHeader /></div>}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}



/**
 * @description style for TableHeader
 */
const TableHeader = () => {
  return (
    <span
      className="fw-bold px-4 flex gap-4 align-items-center"
      style={{
        fontFamily: `"Inter", sans-serif`,
        fontSize: "1.1rem",
        letterSpacing: ".5px",
      }}
    >
      <span>
        <FontAwesomeIcon
          icon={["fa", "cubes"]}
          size={24}
          color={"red"}
        />
      </span>
      Inventory Info
		</span>
  )
}

/**
 * @description check the imageUrl and if it is valid then proceed with that image otherwise use placeholder image
 * @param {*} props 
 * @returns 
 */
export const ImageComponent = ({ imageUrl, width = "300px", height = "270px" }) => {
  const [imgSrc, setImgSrc] = useState(imageUrl || configImages.InventoryPlaceholder);

  const handleError = () => {
    // Set the placeholder image if the provided URL is invalid
    if (imgSrc !== configImages.InventoryPlaceholder) {
      setImgSrc(configImages.InventoryPlaceholder);
    }
  };
  return (
    <div>
        <img
          src={imgSrc}
          alt="Content"
          onError={handleError}
          style={{ objectFit: "cover", width, height }}
        />
    </div>
  );
};


export default ViewInventory