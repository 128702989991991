import propTypes from "prop-types"
import { Dialog } from 'primereact/dialog';


/**
 * @defaultProps for customModal 
 */
const defaultProps = {
  children: null,
  visible: () => {},
  onHide: () => {},
  showHeader: false,
  width: "80vw",
  position: "top",
}

/**
 * @types for CustomModal props
 */
const customModalProps = {
  children: propTypes.node,
  visible: propTypes.func,
  onHide: propTypes.func,
  showHeader: propTypes.bool,
  width: propTypes.string,
  position: propTypes.oneOf[ "center", "top", "bottom", "left", "right", "top-left", "top-right", "bottom-left", "bottom-right" ]
}


const CustomModal = ({ children, width, ...props }) => {
  return (
    <Dialog style={{ width }} contentStyle={{ padding: 0}} {...props}>
        {children}
    </Dialog>
  )
}

CustomModal.defaultProps = defaultProps;
CustomModal.propTypes = customModalProps;

export default CustomModal