import React, { useEffect, useState, useRef, useContext } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';


import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';

import { GlobalContext } from "../../../App/App";
import dateFormats from "../../../UI/FormatDate/formatDate";

import Prescriptions from "../../Prescriptions";
import Accounts from "../../Accounts";
import Insurance from "../../Insurance";
import Forms from "../../Forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const PerioCharts = (props) => {
  const [rolePermission, setRolePermission] = useState();
  const [fullScreen, setFullScreen] = useState();
  const context = useContext(GlobalContext);
  const params = useParams();

  useEffect(() => {

    reset();
    let screenPermissions = RolePermissions.screenPermissions("Perio Charts");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    if (!context.patientData) {
      showToasterMessage('Please select a patient first.', 'warning');
    } else {
      setValue("patientId", context.patientData);
      // getDataFromServer();
    }
  }, [context.patientData]);

  let { handleSubmit, register, reset, setValue, getValues, setError, formState: { errors }, control, watch } = useForm();

  const missingTooth = watch("missingTooth") ? watch("missingTooth") : {};
  const perioChartId = watch("_id");

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  const onClickFullscreen = (screen) => {
    setFullScreen(pre => pre ? undefined : screen);
  }

  const getScreensCards = (screen) => {
    let cards = ['insurance', 'forms', 'prescriptions', 'accounts'];//Components
    let components = { insurance: Insurance, forms: Forms, prescriptions: Prescriptions, accounts: Accounts }

    return cards.map((cardKey, index) => {
      const CardComponent = components[cardKey];
      if (!fullScreen || (fullScreen === cardKey)) {
        return (
          <div className={fullScreen == cardKey ? "fullscreen" : ('screen_card ' + cardKey)} key={index}>
            <CardComponent displayFrom="patientScreens" className={cardKey} >
              {/* Full screen button */}
              <div className="col-md-7 d-flex align-items-center justify-content-end">
                <FontAwesomeIcon
                  icon={fullScreen ? "compress" : 'expand'}
                  title={fullScreen ? 'Exit Full Screen' : 'Full Screen'}
                  style={{ color: '#3c6177', fontSize: '1.5rem', cursor: 'pointer' }}
                  onClick={() => onClickFullscreen(cardKey)}
                />
              </div>
            </CardComponent>
          </div>
        );
      }

    });
  };


  const getUI = () => {

    if (context?.patientData?.name) {
      return <div className="screen_cards">
        {getScreensCards()}
      </div>;
    } else {
      return <h3 className="">Please select patient on the top!</h3>;
    }
  }


  return <div className="patient_screens">
    {getUI()}
  </div>
};

export default PerioCharts;
